<template>
	<el-dialog top="1vh" title="添加司机" width="1050px" :visible.sync="is_show_in_page" @close="colose">

		<el-form class="form_ordinary" label-width="120px">
          	<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机电话" style="width:48%">
					<el-input class="el_inner_width" v-model="tel" clearable>
						<el-button type="success" slot="append" @click="app_user_ser">查找</el-button>
					</el-input>
				</el-form-item>
			</div>
        <div v-if="input_lock">
        <div class="big_tit">APP用户</div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="用户姓名" style="width:44%">
                        <el-input class="el_inner_width" v-model="app_user.name" :disabled="real_status==2 ||input_lock==false"></el-input>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="身份证号码" style="width:44%">
                        <el-input class="el_inner_width" v-model="app_user.id_card_num" :disabled="real_status==2||input_lock==false"></el-input>
                    </el-form-item>
                </div>
                <div class="big_tit">司机信息</div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="驾驶证编号" style="width:44%">
                        <el-input class="el_inner_width" v-model="driver_info.dl_num" :disabled="input_lock!=true||status==2"></el-input>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="准驾车型" style="width:44%">
                        <el-input class="el_inner_width" v-model="driver_info.dl_class" :disabled="input_lock!=true||status==2"></el-input>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="驾驶证发证机关" style="width:44%">
                        <el-input class="el_inner_width" v-model="driver_info.dl_ca" :disabled="input_lock!=true||status==2"></el-input>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="驾驶证有效期自" style="width: 44%">
                        <el-date-picker style="width: 294px" :disabled="input_lock!=true||status==2" v-model="driver_info.dl_start_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="tab1_inner">
                    <el-form-item class="el_form_item" label="驾驶证有效期至" style="width: 44%">
                        <el-date-picker style="width: 294px" :disabled="input_lock!=true||status==2" v-model="driver_info.dl_end_date" type="date" placeholder="选择日期" value-format="timestamp"></el-date-picker>
                    </el-form-item>
                    <el-form-item class="el_form_item" label="从业资格证号" style="width:44%">
                        <el-input class="el_inner_width" :disabled="input_lock!=true||status==2" v-model="driver_info.cy_num"></el-input>
                    </el-form-item>
                </div>
                <div class="big_tit">证件相关</div>
                <div class="tab1_inner">
                        <div class="el_form_item" label="" style="width:48%">
                        <div class="img_btn">
                            <div v-if="!id_card_img1" class="btn">未上传</div>
                            <el-image
                                v-else
                                class="img"
                                :src="id_card_img1"
                                :previewSrcList="id_card_img1_list"
                                :z-index = 3000
                                >
                            </el-image>
                            <!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
                        </div>
                        <div class="bottom_text">身份证正面
                            <el-button v-if="input_lock&&real_status!=2" class="btn_left" size="mini" type="text"  @click="img_upload('id_card_img1')">上传</el-button>
                        </div>
                    </div>
                        <div class="el_form_item" label="" style="width:48%;margin-left:1%">
                        <div class="img_btn">
                            <div v-if="!id_card_img2" class="btn">未上传</div>
                            <el-image
                                v-else
                                class="img"
                                :src="id_card_img2"
                                :previewSrcList="id_card_img2_list"
                                :z-index = 3000
                                >
                            </el-image>
                            <!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
                        </div>
                        <div class="bottom_text">身份证反面
                            <el-button v-if="input_lock&&real_status!=2" class="btn_left" size="mini" type="text" @click="img_upload('id_card_img2')">上传</el-button>
                        </div>
                    </div>
                    <div class="el_form_item" label="" style="width:48%">
                        <div class="img_btn">
                            <div v-if="!dl_img_src" class="btn">未上传</div>
                            <el-image
                                v-else
                                class="img"
                                :src="dl_img_src"
                                :previewSrcList="srcList"
                                :z-index = 3000
                                >
                            </el-image>
                            <!-- <img  v-else :src="dl_img_src" class="img" @click="show_img(dl_img_src)"> -->
                        </div>
                        <div class="bottom_text">驾驶证
                            <el-button v-if="input_lock&&status!=2" class="btn_left" size="mini" type="text" @click="img_upload('dl_img_src')">上传</el-button>
                        </div>
                    </div>
                    <div class="el_form_item" style="width:48%;margin-left:1%">
                        <div class="img_btn">
                            <div v-if="!cy_img_src" class="btn">未上传</div>
                            <el-image
                                    v-else
                                    class="img"
                                    :src="cy_img_src"
                                    :previewSrcList="cyList"
                                    :z-index = 3000
                                    >
                                </el-image>
                            <!-- <img  v-else :src="cy_img_src" class="img" @click="show_img(cy_img_src)"> -->
                        </div>
                        <div class="bottom_text">从业资格证
                            <el-button v-if="input_lock&&status!=2" class="btn_left" size="mini" type="text"  @click="img_upload('cy_img_src')">上传</el-button>
                        </div>
                    </div>
                </div>
            </div>
       

		</el-form>
        <div class="bottom_btns" v-if="real_status!=2||status!=2">
			<el-button v-if="input_lock" type="success" @click="sub()">提交</el-button>

		</div>
        <!-- 凭证添加界面 -->
        <el-dialog
        title="证件上传"
        top="2vh"
        width="500px"
        :visible.sync="upl.is_show"
        :modal="false"
        >
            <div class="upl">
                <div class="img_area" @click="voucher_add_sub">
                <img v-if="upl.src" :src="upl.src" class="show" />
                <i v-else class="el-icon-plus avatar-uploader-icon icon"></i>
                </div>

                <div class="btn_area">
                <el-button type="primary" @click="voucher_sub()">上传</el-button>
                </div>
            </div>
        </el-dialog>
	</el-dialog>
</template>
<script>
	import { Loading } from "element-ui";
	export default {
		props:{
			is_show:Number,//是否显示
		},
		data() {
			return {
                input_lock:false,
                real_status:'',//用户审核是否通过
                status:'',//司机审核是否通过
                type:'',//上传图片得类型
                tel:'',
				//页面是否显示
				is_show_in_page:false,
                //司机信息
                driver_info:{
                    dl_num:'',//驾驶证编号
                    dl_class:'',//准驾车型
                    dl_ca:'',//驾驶证发证机关
                    cy_num:'',//从业资格证号
                    dl_start_date:'',//驾驶证开始时间
                    dl_end_date:'',//驾驶证结束时间
                },
                //app用户
                app_user:{
                    name:'',//姓名
                    id_card_num:'',//身份证号码
                    address:'',//地址
                },

                id_card_img1:'',
				id_card_img1_list:[],

				id_card_img2:'',
				id_card_img2_list:[],

                //驾驶证
				dl_img_src:'',
				srcList: [],
				//从业资格证
				cy_img_src:'',
			    cyList: [],

                //上传界面
                upl: {
                    //是否显示上传界面
                    is_show: false,

                    //地址
                    src: "",
                },
                id_card_img1_key:'',
                id_card_img2_key:'',
                dl_img_src_key:'',
                cy_img_src_key:'',

                driver_add_lock:true,//提交锁
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
				}
			},
		},
		methods: {
            //提交
            sub(){
                Loading.service(this.options);
                 this.$my.net.req({
                    take_over_control:1,
                    data:{
                        mod:'app_user',
						ctr:'set_user_all_api_by_shipper',
                        tel:this.tel,
                        id_card_img1:this.id_card_img1_key?this.id_card_img1_key:'',
                        id_card_img2:this.id_card_img2_key?this.id_card_img2_key:'',
                        id_card_num:this.app_user.id_card_num,
                        address:this.app_user.address,
                        name:this.app_user.name,
                        //驾驶证信息
                        dl_img:this.dl_img_src_key? this.dl_img_src_key:'',
                        dl_num:this.driver_info.dl_num,
                        dl_class:this.driver_info.dl_class,
                        dl_ca:this.driver_info.dl_ca,
                        dl_start_date:this.driver_info.dl_start_date /1000,
                        dl_end_date:this.driver_info.dl_end_date /1000,
                        cy_img:this.cy_img_src_key?this.cy_img_src_key:'',
						cy_num:this.driver_info.cy_num,
                    },callback:(data)=>{
                        this.loading_close()
                       
                        if(data.code ==0){
                            this.$my.other.msg({
                                type: "添加成功",
                                str: "提交成功",
                            });
                             this.is_show_in_page = false;
                        }else{
                            this.$my.other.msg({
                                type: "info",
                                str: data.error_info,
                            });
                        }
                    }
                })
            },
            voucher_sub(){
                if(!this.upl.src){
                    this.upl.is_show = false;
                    return
                }
                Loading.service(this.options);
                //上传
                this.$my.qiniu.upl({
                    bucket: "driversourceproof",
                    file_path: this.upl.src,
                    callback: (data) => {
                    var key = data.key.split('__v__1')
                    //身份证识别
                    if(this.type=="id_card_img1"){
                        this.id_card_img1 = this.upl.src
                        this.id_card_img1_key = key[0]
                        this.$my.net.req({
                            data:{
                                mod:'res',
                                ctr:'img_ocr',
                                img_type:'id_card',
                                img_url:this.$my.qiniu.make_src('truckdl',key[0])
                            },callback:(data)=>{
                              this.app_user.name = data.name
                              this.app_user.id_card_num = data.id_card_num
                              this.app_user.address=data.address
                            //   this.loading_close()
                            }
                        })
                    }
                    //驾驶证
                    if(this.type=="dl_img_src"){
                        this.dl_img_src = this.upl.src
                        this.dl_img_src_key = key[0]
                        this.$my.net.req({
                            data:{
                                mod:'res',
                                ctr:'img_ocr',
                                img_type:'driver_license',
                                img_url:this.$my.qiniu.make_src('truckdl',key[0])
                            },callback:(data)=>{
                                this.driver_info={
                                    dl_num:data.id_card_num,//驾驶证编号
                                    dl_class:data.class,//准驾车型
                                    dl_ca:data.issue_by,//驾驶证发证机关
                                    dl_start_date:new Date(data.valid_date_start).getTime(),//驾驶证开始时间
                                    dl_end_date:new Date(data.valid_date_end).getTime(),//驾驶证结束时间
                                }
                                //  this.loading_close()
                            }
                        })
                    }
                    /////
                    if(this.type=="cy_img_src"){
                        this.cy_img_src = this.upl.src
                        this.cy_img_src_key = key[0]
                        this.$my.net.req({
                            data:{
                                mod:'res',
                                ctr:'ocr_normal_img',
                                cy_img:this.$my.qiniu.make_src('truckdl',key[0])
                            },callback:(data)=>{
                                
                              this.driver_info.cy_num = data.cy_num
                            //   this.loading_close()
                            }
                        })
                    }
                    if(this.type=="id_card_img2"){
                        this.id_card_img2 = this.upl.src
                        this.id_card_img2_key = key[0]
                        // this.loading_close()
                    }
                     this.loading_close()
                    //关闭上传界面
                    this.upl.is_show = false;

                    },
                });
            },
            loading_close(){
                let loadingInstance = Loading.service(this.options);
                    this.$nextTick(() => {
                    loadingInstance.close();
                });
            },
            img_upload(name) {
                this.type = name
                //打开上传界面
                this.upl.is_show = true;

                //清空上次上传的图片
                this.upl.src = "";
            },
            //选择图片
            voucher_add_sub() {
                this.$my.qiniu.choose_img({
                    success: (res) => {

                    //预览
                     this.upl.src = res.src;
                    },
                });
            },
            //弹窗关闭
            colose(num){
                this.input_lock = false
                this.real_status='',//用户审核是否通过
                this.status='',//司机审核是否通过
                this.type='',//上传图片得类型
                this.tel='',
                //司机信息
                this.driver_info={
                    dl_num:'',//驾驶证编号
                    dl_class:'',//准驾车型
                    dl_ca:'',//驾驶证发证机关
                    cy_num:'',//从业资格证号
                    dl_start_date:'',//驾驶证开始时间
                    dl_end_date:'',//驾驶证结束时间
                },
                //app用户
                this.app_user={
                    name:'',//姓名
                    id_card_num:'',//身份证号码
                    address:'',
                },

                this.id_card_img1='',
				this.id_card_img1_list=[],

				this.id_card_img2='',
				this.id_card_img2_list=[],

                //驾驶证
				this.dl_img_src='',
				this.srcList=[],
				//从业资格证
				this.cy_img_src='',
			    this.cyList=[],

                //上传界面
                this.upl= {
                    //是否显示上传界面
                    is_show: false,

                    //地址
                    src: "",
                },
                this.id_card_img1_key='',
                this.id_card_img2_key='',
                this.dl_img_src_key='',
                this.cy_img_src_key=''
            },
            //司机查找
            app_user_ser(){
                this.input_lock = true
                this.$my.net.req({
                    data:{
                        mod:'app_user',
						ctr:'get_user_all_api_by_shipper',
                        tel:this.tel
                    },callback:(data)=>{
                        if(!data.driver_info){
                            this.$my.other.msg({
                                type: "warning",
                                str: "查询为空",
                            });
                            //清空数据
                            this.real_status='',//用户审核是否通过
                            this.status='',//司机审核是否通过
                            //司机信息
                            this.driver_info={
                                dl_num:'',//驾驶证编号
                                dl_class:'',//准驾车型
                                dl_ca:'',//驾驶证发证机关
                                cy_num:'',//从业资格证号
                                dl_start_date:'',//驾驶证开始时间
                                dl_end_date:'',//驾驶证结束时间
                            },
                            //app用户
                            this.app_user={
                                name:'',//姓名
                                id_card_num:'',//身份证号码
                                address:'',
                            },

                            this.id_card_img1='',
                            this.id_card_img1_list=[],

                            this.id_card_img2='',
                            this.id_card_img2_list=[],

                            //驾驶证
                            this.dl_img_src='',
                            this.srcList=[],
                            //从业资格证
                            this.cy_img_src='',
                            this.cyList=[],

                            //上传界面
                            this.upl= {
                                //是否显示上传界面
                                is_show: false,

                                //地址
                                src: "",
                            },
                            this.id_card_img1_key='',
                            this.id_card_img2_key='',
                            this.dl_img_src_key='',
                            this.cy_img_src_key=''
                            return;
                        }
                        this.app_user.name = data.name
                        this.app_user.id_card_num = data.id_card_num
                        this.app_user.address=data.address
                        this.id_card_img1_key = data.id_card_img1
                        this.id_card_img2_key = data.id_card_img2
                        this.dl_img_src_key = data.driver_info.dl_img
                        this.cy_img_src_key =data.driver_info.cy_img
                        this.real_status = data.real_status
                        this.status = data.driver_info.status
                        if(data.id_card_img1.substr(0,4)=='http'){
                            this.id_card_img1=data.id_card_img1
                            this.id_card_img1_list[0]=data.id_card_img1
                        }else{
                            this.id_card_img1=this.$my.qiniu.make_src('driverdl',data.id_card_img1)
                            this.id_card_img1_list[0]=this.$my.qiniu.make_src('driverdl',data.id_card_img1)
                        }

                        if(data.id_card_img2.substr(0,4)=='http'){
                            this.id_card_img2=data.id_card_img2
                            this.id_card_img2_list[0]=data.id_card_img2
                        }else{
                            this.id_card_img2=this.$my.qiniu.make_src('driverdl',data.id_card_img2)
                            this.id_card_img2_list[0]=this.$my.qiniu.make_src('driverdl',data.id_card_img2)
                        }

                        if(data.driver_info.dl_img.substr(0,4)=='http'){
                            this.dl_img_src=data.driver_info.dl_img
                            this.srcList[0]=data.driver_info.dl_img
                        }else{
                            this.dl_img_src=this.$my.qiniu.make_src('driverdl',data.driver_info.dl_img)
                            this.srcList[0]=this.$my.qiniu.make_src('driverdl',data.driver_info.dl_img)
                        }
                        if(data.driver_info.cy_img.substr(0,4)=='http'){
                            this.cy_img_src=data.driver_info.cy_img
                            this.cyList[0]=data.driver_info.cy_img
                        }else{
                            this.cy_img_src=this.$my.qiniu.make_src('driverdl',data.driver_info.cy_img)
                            this.cyList[0]=this.$my.qiniu.make_src('driverdl',data.driver_info.cy_img)
                        }
                        let info = data.driver_info
                        info.dl_start_date = Number(info.dl_start_date) *1000
                        info.dl_end_date = Number(info.dl_end_date) *1000
                        this.driver_info={
                            dl_num:info.dl_num,//驾驶证编号
                            dl_class:info.dl_class,//准驾车型
                            dl_ca:info.dl_ca,//驾驶证发证机关
                            dl_start_date:info.dl_start_date,//驾驶证开始时间
                            dl_end_date:info.dl_end_date,//驾驶证结束时间
                        }
                    }
                })
            },
		}
	}
</script>
<style lang="scss" scoped>

.el_form_item {
  margin: 0;
  .img_btn {
    border: 1px solid #bbb;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    height: 300px;
    overflow: hidden;
    .btn {
      text-align: center;
      line-height: 290px;
      font-size: 14px;
    }

    .img {
      position: relative;
      display: block;
      height: 290px;
      /deep/.el-image__inner {
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
      }
    }
  }
  .bottom_text {
    margin-top: 5px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 15px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
.upl {
  .img_area {
    border: 1px solid #ccc;
    cursor: pointer;
    padding: 5px;
    .show {
      margin: auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    .icon {
      text-align: center;
      display: block;
      font-size: 100px;
      height: 500px;
      line-height: 500px;
    }
  }
  .mark_area {
    border: 1px solid #ccc;
    padding: 5px;
    width: 448px;
    margin-top: 10px;
    height: 100px;
    outline: none;
    font-size: 16px;
  }
  .mark_area::placeholder {
    font-size: 20px;
    line-height: 100px;
    text-align: center;
  }
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
}

.voucher {
  margin-top: -10px;
  .header {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .list_none {
    height: 300px;
    line-height: 300px;
    text-align: center;
    font-size: 18px;
  }
}
.cy_num_text{
	font-size: 12px;
	color: red;
}
</style>