<template>
	<el-dialog top="1vh" width="80%" height='px' :visible.sync="is_show_in_Page">
    <el-button type="primary"  @click="captureElement(1,0)">下载</el-button>
    <div class="page">
    <!-- 税务上报模板 -->
    <div style="display:flex;">
		<div class="contract-container"  ref="content">
            <div class="head">运输协议</div>
             <div class="maks">
                本《货物运输协议》（“本协议”）系由托运人（“网络货运人”）与实际承运人 （“承运人”）在骏功网络货运平台（“平台”）上，就承运人为网络货运人提供货物 运输服务所订立的有效合约。在确认本协议之前，请承运人仔细阅读本协议的全部内容（特别是以粗体及/或下划线标注的内容）。如承运人不同意本协议的任意内容，或者无法准确理解协议任何条款的含义，请不要进行确认及后续操作。 
            </div>
            <div class="maks">
               承运人通过确认/提交/同意/接受/点击/勾选/进行下一步操作的形式，确认骏功平台相关页面上的本协议或以其他方式确认接受本协议或已实际享有本协议项下的任何权益或 已实际执行本协议项下的任何约定（“确认”），即表示承运人同意与网络货运人签署本协 并同意接受本协议全部约定内容的约束。本协议经网络货运人和承运人确认即生效，具有法律效力。 
            </div>
            <div class="content">
                本协议项下托运人与承运人合称为“双方”，单独地称为“一方”。
            </div>
            <div class="xieyi-info">
                <div class="xieyi-top">
                    <div class="Party-a">
                        托运人(网络货运人)信息
                    </div>
                    <div class="Party-b">
                        承运人信息
                    </div>
                </div>
                <div class="xieyi-content">
                    <div class="info-a">
                        <div>
                            托运人:安徽省骏功物联网科技有限公司
                        </div>
                        <div>
                            统一社会信用代码:91340802MA2WF25Y7R
                        </div>
                        <div>
                            联系方式:0556-5888588
                        </div>
                        <div>
                            下单时间：{{data.cargo_creat_time_text}}
                        </div>
                    </div>
                    <div class="info-b">
                        <div>
                            承运人:{{driveragreement_data.name}}
                        </div>
                        <div>
                            身份证号:{{driveragreement_data.id_card_num}}
                        </div>
                        <div>
                            联系方式:{{driveragreement_data.tel}}
                        </div>
                        <div>
                           车牌号:{{driveragreement_data.truck_plate_num}}
                        </div>
                        <div>
                           接单时间:{{data.creat_time_text}}
                        </div>
                    </div>
                </div>
                <div class="xieyi-top">
                    <div style="margin-left: 20px;">
                        运单信息
                    </div>
                </div>
                <div class="xieyi-bottom">
                    <div class="info-a">
                        <div>
                            出发地:{{driveragreement_data.case_adr}}
                        </div>
                        <div>
                            货物名称:{{data.cargo_name}}
                        </div>
                        <div v-if="data.truck_info">
                            车型车长:{{data.truck_info.truck_type_name_text1}}
                        </div>
                    </div>
                    <div class="info-b">
                        <div>
                           目的地:{{driveragreement_data.aim_adr}}
                        </div>
                        <div>
                            结算方式:银行转账
                        </div>
                    </div>
                </div>
            </div>
            <div class="content" style="margin-top:10px">
               1.运输交易的达成
            </div>
            <div class="content">
               1.1承运人确认并同意，由网络货运人委托承运人承接运单所载明的相关货物运输服务。
            </div>
            <div class="content">
               1.2承运人确认并同意，网络货运人通过骏功网络货运平台与承运人就货运信息进行沟通、填写、提交及/或确认，并认可以此方式达成的交易。
            </div>
            <div class="content">
               1.运输交易的达成
            </div>
            <div class="content">
               2.运单的生成、变更及取消
            </div>
            <div class="content">
               2.1 网络货运人与承运人就货物运输达成合意生成运单，运单载明了货运信息、运费等信息。
            </div>
            <div class="content">
               2.1 网络货运人与承运人就货物运输达成合意生成运单，运单载明了货运信息、运费等信息。
            </div>
            <div class="content">
               2.2 如通过骏功网络货运平台修改或重新生成了运单，则承运人应当按修改或重新生成的运单或补充协议继续履行本协议，重新生成的运单与补充协议中与之前的运单不一致的，以最新生成的运单或补充协议为准。
            </div>
            <div class="maks">
              2.3 在承运人将货物交付收货人之前，网络货运人/发货人有权根据实际情况或发货人的要求进行终止运输、返还货物、变更送达地点或变更收货人等操作。
            </div>
            <div class="content">
               3.运费的支付
            </div>
             <div class="content">
                <div style="width:250px;margin:0">3.1网络货运人支付承运人的总运费为</div><span style="width:100px;margin-left:10px;margin-right:10px;border-bottom: 1px solid black;">{{driveragreement_data.give_carrier_total}}</span>元
            </div>
            <div class="content">
                <div style="width:600px">
                    <span style=" font-weight: 800;">①总运费包括但不限于预付款、预付物资金额（例如油品等）、到付款、回单款路桥费、折旧、人工等全部费用。</span>
                    <span>②如承运人给网络货运人开具国家规定的《公路、内河货物运输业统一发票》或《货物运输业增值税专用发票》，则不扣减代开发票费用；</span>
                    <span style=" font-weight: 800;">如若承运人无法开具相应发票至网络货运人，网络货运人将委托第三方服务机构办理运输业务代开发票及缴纳税费，代开发票产生的税额和费用由承运人承担，如承运人不接受网络货运人提供的油卡且不能提供前述发票，则承运人同意由网络货运人代为开具前述发票。</span>
                </div>
            </div>
            <div class="content">
                <div>
                    3.2尾款确认：<span style=" font-weight: 800;">①回单作为尾款结算凭证，运输到达时间截止后3日内回单原件需寄给网络货运人；②油卡可按照实际消费情况寄回，油卡余额为零后7日内未寄回，则从尾款中扣除油卡押金；③网络货运人为承运人提供加油服务，在运费中予以扣除，加油记录可在APP内查询。</span>
                </div>
            </div>
            <div class="maks">
              4.承运人不可撤销地确认并同意，网络货运人以下列条件的达成和满足作为支付承运人运费的前提： 
            </div>
            <div class="maks">
             （1）承运人全部履行完毕其在本协议项下的义务； 
            </div>
            <div class="maks">
              （2）托运人与发货人确认无货损货缺； 
            </div>
            <div class="maks">
             （3）运单有效性验证通过；
            </div>
            <div class="maks">
              （4）托运人已向网络货运人支付运单运费。
            </div>
            <div class="maks">
              网络货运人应在上述条件均满足后的5个工作日内向承运人支付运费。如上述任何前提和条件没有达成或满足，则网络货运人有权拒绝且无需向承运人支付及交付本协议项下的运费及物资，直至该等前提和条件全部达成或满足，承运人不得以此为由向网络货运人主张任何权利。
            </div>
            <div class="content">
              5.双方权利义务
            </div>
            <div class="content">
                5.1 承运人应在约定的卸货时间前将货物运输至收货地。
            </div>
            <div class="maks">
              5.2承运人确认并同意在承运车辆上安装卫星定位设备（车载卫星定位、便携式卫星定位等）并在运输过程全程开启该等卫星定位设备并打开手机定位功能及骏功平台定位功能并保证手机畅通并实时上传其位置信息。承运人在此不可撤销地同意并授权，承运人在骏功平台接单后，由网络货运人在骏功平台现有技术允许的范围内，通过承 运人驾驶员的手机定位功能、骏功平台定位功能、承运车辆上安装的卫星定位设备及/或前述功能的服务供应商（可同时使用上述功能、设备及/或方式中的多种）对承运人驾驶员的实时位置及车辆行驶轨迹进行监控，且网络货运人有权随时要求承运人向网络货运人报备其驾驶员实时位置及车辆行驶轨迹并向网络货运人提供其实时位置及行驶轨迹的相关证据（包括但不限于行驶轨迹截图、完整通行路段ETC发票的彩色照片、电子发票及货物发货的出库单、货物入库的签收单等），并将承运人驾驶员的实时位置和车辆的行驶轨迹记录在骏功平台，以确保货物及运输过程的安全，满足网络货运人对运输过程进行管控的要求。承运人于此不可撤销地同意，允许网络货运人将必要的承运人个人信息（如承运人姓名、行驶证等）提供给前述车载定位服务提供商以实现对承运人的实时位置及行驶轨迹的监控、查询。 
            </div>
            <div class="content">
               5.3 网络货运人客户回单、货物清单、过磅单等标注承运货物名称、件数、重量及体积等信息，货物总价以每票货物实际价值的总和为准。
            </div>
            <div class="maks">
              5.4 承运人运输相关资质问题导致的包括但不限于行政罚款、滞纳金、网络货运人向托运人承担的违约责任等损失由承运人承担。
            </div>
            <div class="content">
                <div>
                   5.5 承运人应遵守网络货运人及网络货运人客户的运输管理安排、考核制度等所有内容，<span style=" font-weight: 800;">网络货运人客户对网络货运人的罚款由承运人承担，网络货运人有权在尾款中扣除。</span>
                </div>
            </div>
            <div class="maks">
             5.6 未经网络货运人同意禁止承运人在途中更换车辆，否则，所有损失由承运人承担，给网络货运人造成损失的，还应赔偿网络货运人的全部损失；因不可抗力导致的车辆途中维修、事故、堵车、恶劣天气等，需向网络货运人及时报备影响时间，便于网络货运人予以减免因此产生的时效罚款；
            </div> 
            <div class="content">
                <div style="width:600px">
                    5.7承运人应提供安全合法的车辆以及司乘人员并按法律要求缴纳人员/车辆保险和货物保险，
                    <span style=" font-weight: 800;">道路运输过程中发生交通事故以及其他事故的，由承运人承担全部责任，</span>
                    <span>与网络货运人无关；同时，</span>
                    <span style=" font-weight: 800;">因承运人责任导致现场处理的，承运人承担网络货运人往返差旅费用；</span>
                </div>
            </div>
            <div class="maks">
            货物交运后，除因不可抗力、货物本身的自然性质造成的货物毁损或灭失的风险与损失
以外，承运人将货物交付收货方（以收货方签署的回单上记载的时间为准）之前发生的货物毁损或灭失的风险与损失由承运人承担。如承运人认为货物毁损或灭失系因不可抗力及货物本身的自然性质造成或托运人、收货人过错造成的，承运人需提供详细说明及书面证明，经网络货运人审查认可的，不承担违约责任，无法提供证明的，承运人须承担违约责任及全部赔偿责任。承运人伪造或变造客户签收回单的承运人应赔偿全部损失。
            </div> 
            <div class="maks">
            5.9 任何情况下禁止承运人留置托运货物，否则，网络货运人有权解除合同并要求承运人赔偿网络货运人全部损失。
            </div> 
            <div class="content">
                <div>
                    5.10承运人运费是由网络货运人支付且已经包含了通行费费用，<span style=" font-weight: 800;">承运人知晓并同意以下内容：(1)在本运输合同中起运地到收货地所产生的区间过桥过路通行费票据的开具、归集、抵扣等所有权利归甲方所有，在本运输合同中起运地到收货地所产生的区间路桥、ETC等费用由甲方支付，金额已包含在总运费中。(2)在本运输合同中起运地到收货地运输期间，乙方已取得ETC卡持卡人或使用权人关于使用ETC卡的合法授权(3) 在本运输合同中起运地到收货地运输期间，承运人承诺产生的路桥 ETC 等费用发票直接开具给甲方且放弃对前述票据的任何权利，同意前述票据可由甲方直接或通过第三方平台进行相关业务服务。</span>
                </div>
            </div>
            <div class="maks">
            5.11 如承运人未能按照本协议的约定及运单的要求提供货物运输服务或者在货物运输服务中发生违反本协议、运单或骏功平台规则的情形，则网络货运人有权解除本协议，拒绝向承运人支付任何物资或运费，就网络货运人已向承运人支付的物资或运费，网络货运人有权要求承运人返还给网络货运人，并且承运人的行为给网络货运人及/或第三方造成任何直接或间接损失的，承运人还应足额赔偿网络货运人和第三方并承担违约金10万元。
            </div> 
            <div class="content">
              5.12 网络货运人的全部损失包括但不限于网络货运人客户对网络货运人的罚款、对第三方的赔偿、差旅费、诉讼费、律师费、调查费、鉴定费、保全费、保险服务费等。
            </div>
            <div class="maks">
            6. 保险的购买
            </div> 
            <div class="maks">
            6.1承运人应在承运本协议项下所涉货物之前，应自行办理足额的载运工具财产保险、第三者责任险以及相关作业人员的意外伤害险等并确保在本协议有效期限内该等保险持续有效。承运人应确保在发生货物损失（“货损”）及其他损失时，可获得保险公司的赔付。若承运人未购买、未及时购买或购买保险的保险金额不足以覆盖货损或其他损失和责任的，由此造成的全部损失由承运人自行承担。 
            </div> 
            <div class="maks">
             6.2 在承运人及时、足额购买了保险的情况下，若保险公司最终认定不予赔付或不予全额赔付，造成网络货运人损失的，承运人应赔偿网络货运人损失；造成承运人自身损失的，承运人应自行承担全部损失。 
            </div> 
            <div class="maks">
            6.3 发生货物损失时，承运人应当按照本协议约定先行向网络货运人履行赔偿义务，不得以保险尚未赔付为由拒绝或拖延赔偿网络货运人损失。
            </div> 
            <div class="maks">
            7.鉴于网络服务的特殊性（包括但不限于因骏功平台软硬件维护或升级、电信部门技术调整、故障、因政府管制而造成的暂时性关闭、设备故障、黑客攻击等不可抗力等原因），网络货运人可于骏功平台公告通知后变更、中断或终止部分或全部的骏功平台服务，且无需为此向承运人承担责任。
            </div> 
            <div class="maks">
            8.本协议项下网络货运人对于承运人所有的通知均可通过骏功平台公告、电子邮件、手机短信、APP/服务器推送、常规信件或其他通讯方式进行；网络货运人可任意选择其中一种方式对承运人发出通知，该等通知应于发送到达之时视为已送达承运人；承运人确认，除承运人明确向网络货运人做出相反的意思表示外，其在通知送达时已知悉并接受通知之全部内容。
            </div> 
            <div class="maks">
            9.如有争议由双方协商解决，协商不成的提交安庆市迎江区人民法院诉讼。
            </div> 
            <div class="maks">
            10.本协议包括协议正文、附件（包括且不限于平台发布的各类有关规则及解读、规范、通知、公告、《法律文书送达地址确认书》）、补充协议等，均为本协议不可分割的一部分，与协议正文具有同等法律效力。前述协议正文、附件、规则、通知、公告等文件冲突的，除另有说明外，以发布在后的文件为准。
            </div> 
            <div class="content">
               11.本协议一式三份，网络货运人持两份、承运人持一份，具同等效力。
            </div>
            <div class="content">
               12.本协议在网络货运人实现永久电子存档，承运人明确以上条款后请在APP端或小程序进行同意确认后生效。
            </div>
            <div class="content" style="margin-top:70px;margin-bottom:150px;">
                <div style="width:600px;margin:0;"><span style="margin-right:200px;margin-left:-5px;border:0;">甲方（盖章）</span> <img class="img"  src="../../../../../res/img/zhang.png" alt=""><span style="border:0;margin-left:50px">乙方（签字或盖章）</span><span style="border:0;font-size:30px;font-family:'微软雅黑'">{{driveragreement_data.name}}</span>
                </div>
               
            </div>
            <div class="maks">
                附件：1
            </div> 
            <div class="head">送达地址确认书</div>
            <div class="content">
              致：安徽骏功物联网科技有限公司
            </div>
            <div class="content" style="margin-top:50px;">
              本人 {{driveragreement_data.name}}      ，身份证号：       {{driveragreement_data.id_card_num}}         ，与安徽骏功物联网科技有限公司签署了《货物运输协议》，现就有关文书送达事宜做如下确认：
            </div>
            <div class="content">
              一、本人确认并声明以下送达信息为本人所提供，且为前述合同及保险单项下所涉运输文件、通知及诉讼（仲裁）法律文书送达地址和联系方式：
            </div>
            <div class="content" v-if="data.driver_info">
             送达地址：{{data.driver_info.addr}}
            </div>
            <div class="content">
              收件人（或代收人）： {{driveragreement_data.name}}
            </div>
            <div class="content">
             联系电话：	{{driveragreement_data.tel}}
            </div>
            <div class="content">
            二、本确认书第一条约定的送达地址适用于涉及《货物运输协议》的相关事项及导致的诉讼（仲裁）等情形，安徽骏功物联网科技有限公司及其关联方或其委托的第三方、托运人、受诉法院或仲裁机构按第一条确认的地址向本人发送相关文书时，若发生无人签收、拒收或被退回等送达不能情形的，则该文书退回之日即视为送达之日；若采取直接送达时，如果拒收的，送达人可采取拍照、录像方式记录送达过程，并将文书留置，该文书一经留置即视为送达。
            </div>
            <div class="content">
             三、本确认书第一条约定的联系电话，同样适用于涉及《货物运输协议》相关事项及导致诉讼（仲裁）等情形，安徽骏功物联网科技有限公司及其关联方或其委托的第三方、托运人、受诉法院或仲裁机构按第一条确认的信息用手机短信等现代通讯方式向本人发送了相关文书的，即视为送达。
            </div>
            <div class="content">
            四、若《货物运输协议》所涉义务履行完毕之前，本人对第一条约定的送达信息作出变更的，则承诺在变更之日起五日内以书面通知书形式告知安徽骏功物联网科技有限公司，该通知书需详细列明变更后的送达地址、收件人、联系电话等必要信息；否则，送达信息仍以第一条确认的为准，本人对此不持任何异议。
            </div>
            <div class="content">
            五、若本人提供错误或不准确的送达信息或未及时书面告知变更后的送达信息，导致相关文书未能送达或退回的，则该文书退回之日即视为送达之日。
            </div>
            <div class="maks">
           六、安徽骏功物联网科技有限公司对本确认书条款已向本人提请注意并主动进行了充分的释明，本人对各条款的含义及其相应法律后果均已清楚、明确知晓。
            </div> 
		</div>
	</div>
  </div>
	</el-dialog>
</template>
<script>
import html2canvas from 'html2canvas'
	export default {
		props:{
			is_show:Number,//是否显示
			data:[Object,String],//运单详情
		},
		data() {
			return {
				//列表是否显示
				is_show_in_Page:false,
                driveragreement_data:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
                    console.log(this.data)
                    this.data.cargo_creat_time_text = this.$my.other.totime(this.data.cargo_creat_time);
                    this.data.truck_info.truck_type_name_text1=`
                        ${this.data.truck_info.truck_type_name?this.data.truck_info.truck_type_name+'/':''}
                        ${this.data.truck_info.truck_type_name2?this.data.truck_info.truck_type_name2:''}
                    `;
					//打开页面
					this.is_show_in_Page=true;
                    this.get_page_data()
				}
			},
		},
		methods: {
             captureElement() {
                 let name = this.driveragreement_data.name + '-'+this.driveragreement_data.truck_plate_num
                const element = this.$refs.content; // 获取要生成图片的DOM元素
                var scrollHeight = element.scrollHeight
                var scrollWidth = element.scrollWidth
                html2canvas(element,{
                     cale: window.devicePixelRatio*2,
                    useCORS: true , //开启跨域配置，但和allowTaint不能共存
                    width:scrollWidth,
                    height:scrollHeight,
                    windowWidth:scrollWidth,
                    windowHeight:scrollHeight,
                    x:0,
                    y:window.pageYOffset
                }).then((canvas) => {
                    // 创建一个图片元素
                    let img = new Image();
                    img.src = canvas.toDataURL('image/png');
                    // 这里可以将img元素插入到DOM中，例如插入到body中
                    document.body.appendChild(img);
            
                    // 或者可以直接下载图片
                    let a = document.createElement('a');
                    a.href = img.src;
                    a.download = name+'.png';
                    a.click();
                });
            },
            //协议下载
            createImg(content){
                var content = this.$refs['content']
                var scrollHeight = content.scrollHeight
                var scrollWidth = content.scrollWidth
                html2canvas(content,{
                    cale: window.devicePixelRatio*2,
                    useCORS: true , //开启跨域配置，但和allowTaint不能共存
                    width:scrollWidth,
                    height:scrollHeight,
                    windowWidth:scrollWidth,
                    windowHeight:scrollHeight,
                    x:0,
                    y:window.pageYOffset
                    }).then((canvas) => {
                        let dataURL = canvas.toDataURL("image/jpg");

                        let link = document.createElement("input");    
                        link.value = dataURL;
                        let filename = `${new Date().getTime()}.jpg`;  //文件名称
                        link.setAttribute("download", filename);
                        link.style.display = "none"; //a标签隐藏
                        document.body.appendChild(link);
                        link.className="link"
                        link.click()
                        console.log(link,999);return
                        var ref = document.getElementsByClassName('link')
                        console.log(ref)
                        var blob = this.$my.base64.dataURLtoBlob(ref[0].value)
                        // console.log(src);
                        // this.upl.src=src
                        ref[0].remove()
                        const file=new window.File(
                            [blob],
                            "123.png",
                            {type:"image/png"}
                            )
                            console.log(file)
                            
                            //获取token
                            // trucktordrealtimevoucher
                            this.$my.net.req({
                            data:{
                                mod:'res',
                                ctr:'get_qiniu_upl_token_api',
                                bucket:"trucktordrealtimevoucher",
                                key:"",  //有key是编辑   无key是新增
                            },
                            callback:(data)=>{
                            console.log("data");
                            console.log(data);
                            // data.key
                            // data.region
                            // data.token
                            // data.url
                            
                            //执行上传
                            let form=new FormData();// FormData 对象
                            form.append("file",file); // 文件对象
                            form.append("token",data.token); // 文件对象
                            form.append("key",data.key); // 文件对象
                            
                            form.file=file;
                            form.token=data.token;
                            form.key=data.key;
                            
                            let xhr = new XMLHttpRequest();  // XMLHttpRequest 对象
                            xhr.open("post",data.url,true); //post方式，url为服务器请求地址，true 该参数规定请求是否异步处理。
                            xhr.onload = (res)=>{//请求完成
                            console.log("请求完成")
                            if(res.target.status!=200){
                                this.$my.other.msg({
                                type:'warning',
                                str:'上传失败'
                                });
                                return;
                            }
                            var key = data.key.split('__v__1')
                                //上传
                                this.$my.net.req({
                                    data:{
                                        mod:'res',
                                        ctr:'up_contract',
                                        url:key[0],
                                        code:this.code,
                                        type:2
                                    },
                                    callback:(data)=>{
                        console.log("上传成功",2222);
                                        console.log(this.code,444);
                        this.is_show_in_Page = false
                                    this.$my.other.msg({
                                            type:'success',
                                            str:"上传成功"
                                        });
                                    }
                                });
                    this.n++
                    this.$emit('childEvent',this.n)
                    console.log("上传成功");
                    console.log("http://imgreport.jungong56.com/"+data.key)
                            };
                            xhr.send(form); //开始上传，发送form数据
                            }
                        });
                        return
                        link.click();
                })
            },
            toGetPdf(){
                let name = this.driveragreement_data.name + '-'+this.driveragreement_data.truck_plate_num
                this.getPdf(name)
            },
            getPdf(titile){
                var content = this.$refs['content']
                var scrollHeight =1800
                    var scrollWidth = 800
                html2canvas(content,{
                    allowTaint:true,
                }).then(canvas=>{
                    //内容的宽度
                    let contentWidth =scrollWidth;
                    //内容的高度
                    let contentHeight =scrollHeight;
                    //一页pdf显示htm1页面生成的canvas高度，a4纸的尺寸[595.28,841.89];
                    let pageHeight =contentWidth / 592.28 * 500;
                    //未生成pdf的htm1页面高度
                    let leftHeight = contentHeight
                    //页面偏移
                    let position = 0;
                    //a4纸的尺寸[595.28,841.89],htm1页面生成的canvas在pdf中图片的宽高
                    let imgwidth=500;
                    let imgHeight =600 / contentWidth * contentHeight
                    //canvas转图片数据
                    let pageData = canvas.toDataURL('img/jpeg',1.0);
                    //新建JSPDF对象
                    let PDF = new JsPDF('','pt', 'a4')
                    //判断是否分页
                    if(leftHeight<pageHeight){
                    PDF.addImage(pageData, 'JPEG',0,0,imgwidth,imgHeight)
                    }else{
                    while(leftHeight>0){
                        PDF.addImage(pageData, 'JPEG',0,position,imgwidth,imgHeight);
                        leftHeight -= pageHeight;
                        position -= 84189;
                        if(leftHeight>0){
                        PDF.addPage()
                        }
                    }
                    }
                    PDF.save(titile)
                })
            },
            get_page_data(){
                if(this.data.truck_tord_num){
                    this.$my.net.req({
                        data:{
                            mod:'res',
                            ctr:'driveragreement_api',
                            truck_tord_num_act:this.data.truck_tord_num
                        },callback:(data)=>{
                            this.driveragreement_data = data
                        }
                    })
                }else{
                    this.$my.net.req({
                        data:{
                            mod:'res',
                            ctr:'driveragreement_api',
                            bl_num:this.data.bl_num
                        },callback:(data)=>{
                            this.driveragreement_data = data
                        }
                    })
                }
            }
		}
	}
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // height: calc(100% - 40px);
  overflow: auto;
}
.contract-container{
    margin-top: 30px;
    margin: auto auto;
    width:2000px;
    color: #000;;
    padding-left: 25%;
    height: 1600px;
    // text-align: center;
    .head{
    margin-top: 40px;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 50px;
    margin-left: 320px;
    }
    .content{
        margin-left: 120px;
        display: flex;
        margin-bottom: 4px;
        text-indent: 2ch;
        width: 600px;
        // div{
        //     width: 100px;
        // }
        // span{
        //     width: 400px;
        //     margin-left: 10px;
        //     border-bottom: 1px solid black;
        // }
    }
    // .maks{
    //     text-align: left;
    //     margin-left: 150px;
    //     width: 500px;
    // }
}
.img{
  width: 140px;
  margin-left: -180px;
  position:relative;
  bottom: -50px;
}
.maks{
    text-align: left;
    margin-left: 120px;
    width: 600px;
    text-indent: 2ch;
    font-weight: 800;
}
.xieyi-info{
    margin-left: 100px;
    width: 600px;
    height: 270px;
    border: 1px solid black;
    .xieyi-top{
        display: flex;
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid black;
        .Party-a{
            width: 300px;
            border-right: 1px solid black;
            margin-left: 20px;
        }
        .Party-b{
            margin-left: 20px;
        }
    }
    .xieyi-content{
        display: flex;
        height: 110px;
        // line-height: 30px;
        border-bottom: 1px solid black;
        .info-a{
            padding-top: 10px;
            width: 300px;
            border-right: 1px solid black;
            margin-left: 20px;
        }
        .info-b{
            padding-top: 5px;
            margin-left: 20px;
        }
    }
    .xieyi-bottom{
        display: flex;
        height: 80px;
        .info-a{
            padding-top: 5px;
            width: 300px;
            // border-right: 1px solid black;
            margin-left: 20px;
        }
        .info-b{
            padding-top: 5px;
            margin-left: 20px;
        }
    }
}
</style>