<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="开票编号">
				<el-input class="el_input" v-model="form.invoice_num" placeholder="流水开票编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待开票" value="1"></el-option>
					<el-option label="已开出" value="2"></el-option>
					<el-option label="已驳回" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="发票号">
				<el-input class="el_input" v-model="form.bill_num" placeholder="发票号搜索" clearable></el-input>
			</el-form-item>
			 <el-form-item class="el_form_item elfrom_item" label="申请时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="开票时间">
				<div class="block">
				<el-date-picker
					v-model="invoice_time"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="table">
			<div class="thead">
				<el-checkbox style="margin:10px 13px;" @change="all_choose"></el-checkbox>
				<!-- <el-button-group style="margin: -4.5px 0 0 10px;">
					<el-button size="mini" type="danger" @click="choosed_invoice_cancel">取消选中的开票申请</el-button>
				</el-button-group> -->
				<el-button-group style="margin: -4.5px 0 0 10px;">
					<el-button size="mini" type="success" @click="choosed_out">导出搜索结果</el-button>
				</el-button-group>
			</div>
			<div class="tbody">
				<div class="invoice_list" v-for="(invoice_item,invoice_index) in list.data" :key="invoice_index">
					<div class="invoice_info"  @click="invoice_info(invoice_item)">
						<div class="item" style="padding-top:6px">
							<el-checkbox v-model="invoice_num_choosed[invoice_item.invoice_num].checked"></el-checkbox>
						</div>
						<div class="item" style="width:180px;margin-left:8px">
							<div class="top">{{invoice_item.invoice_num}}</div>
							<div class="bottom">申请时间{{invoice_item.creat_time_text}}</div>
						</div>
						<div class="item" style="width:150px">
							<div class="top">金额:{{invoice_item.invoice_total}}元</div>
							<div class="bottom">票号:{{invoice_item.bill_num}}</div>
							
						</div>
						<div class="item" style="width:230px">
							<div class="top">状态:{{invoice_item.invoice_status_text}}</div>
						</div>
						<div class="item" style="width:180px">
							<div class="top">运单数量:{{invoice_item.act_num}}</div>
						</div>
						<!-- <div class="item" style="flex:1;text-align: right;line-height: 30px;margin-right:7px">
							<el-button v-if="invoice_item.status==1" @click="invoice_cancel(invoice_item)" size="mini" type="danger">取消开票申请</el-button>
							<el-button v-if="invoice_item.status==3" @click="invoice_cancel(invoice_item)" size="mini" type="danger">取消开票申请</el-button>
						</div> -->
					</div>
					<div class="tord_list" v-for="(tord_item,tord_invoice) in invoice_item.tord_list" :key="tord_invoice"  v-show="invoice_item.invoice_ishow">
						<div class="tord_info">
							<div class="item" style="width:180px">
								<div class="top">运单:{{tord_item.truck_tord_num}}</div>
								<div class="bottom">下单:{{tord_item.creat_time_text}}</div>
							</div>
							<div class="item" style="flex: 1;">
								<div class="top">发货地:{{tord_item.case_prov}}/{{tord_item.case_city}}/{{tord_item.case_county}}</div>
								<div class="bottom">{{tord_item.case_other_obj.addr_info}}</div>
							</div>
							<div class="item" style="flex: 1;">
								<div class="top">到货地:{{tord_item.aim_prov}}/{{tord_item.aim_city}}/{{tord_item.aim_county}}</div>
								<div class="bottom">{{tord_item.aim_other_obj.addr_info}}</div>
							</div>
							<div class="item" style="width:130px">
								<div class="top">货物:{{tord_item.cargo_type}}/{{tord_item.cargo_name}}</div>
								<div class="bottom">{{tord_item.cargo_weight}}吨/{{tord_item.cargo_volume}}方</div>
							</div>
							<div class="item" style="width:130px">
								<div class="top">总运费:{{tord_item.cost_info.freight_total}}元</div>
								<div class="bottom">已支付:{{tord_item.cost_info.payed_total_of_all}}元</div>
							</div>
							<div class="item" style="width:150px">
								<div class="top">收款人:{{tord_item.truck_owner_info.name}}/{{tord_item.truck_owner_info.tel}}</div>
								<div class="bottom">司机:{{tord_item.driver_info.name}}/{{tord_item.driver_info.tel}}</div>
							</div>
							<div class="item" style="width:130px">
								<div class="top">车辆:{{tord_item.truck_plate_num}}</div>
							</div>
						</div>
						<div class="payed_list">
							<div class="payed_info" v-for="(payed_item,payed_index) in tord_item.payed_list" :key="payed_index">
								<div class="item" style="width:250px">
									<div>{{payed_item.payed_num}}</div>
									<div>{{payed_item.creat_time_text}}</div>
								</div>
								<div class="item" style="width:300px;">
									<div>[支付]:{{payed_item.this_payed_text}}/服务费:{{payed_item.service_charge_text}}</div>
									<div>状态:{{payed_item.pay_status_text}}</div>
								</div>
								<div class="item" style="width:210px;">
									<div>[发票]:{{payed_item.this_invoiced}}元</div>
									<div>状态:{{payed_item.invoice_status_text}}</div>
								</div>
								<div class="item" style="width:240px;" v-if="payed_item.calc_mode==1">
									<div>[结算给车队长]:{{payed_item.this_settled_of_truck_owner}}元</div>
									<div>状态:{{payed_item.settle_status_of_truck_owner_text}}</div>
								</div>
								<div class="item" style="width:340px;" v-if="payed_item.calc_mode==1">
									<div>[结算给司机]:{{payed_item.this_settled_of_driver}}元/类型:{{payed_item.this_pay_type_text}}</div>
									<div>状态:{{payed_item.settle_status_of_driver_text}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<serRelExport 
			:is_show="tord_export.is_show" 
			:rull="tord_export.rull" 
			@close="tord_export_close" 
		></serRelExport>
	</div>
</template>

<script>
	import serRelExport from './invoice_excle.vue'
	import { Loading } from "element-ui";
	export default {
		components:{

			serRelExport,

		},
		data() {
			return {

				//搜索条件
				form: {				
					invoice_num:'',//开票编号
					shipper_tel:'',//货主手机号
					status:'',//开票状态(1:待开票,2:已开出,3:已驳回)
					bill_num:'',//发票号
					creat_time_start:'',//申请开始时间
					creat_time_end:'',//申请结束时间
					invoiced_time_start:'',//开出开始时间
					invoiced_time_end:'',//开出结束时间
				},
				invoice_number_list:'',
				invoice_code_list:'',
				date_value:"",
				invoice_time:'',//开票时间
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//多选数据
				invoice_num_choosed:{},

				//导出搜索结果界面
				tord_export:{
					is_show:0,
					rull:{}
				},
			}
		},
		created() {
			
			//取出参数
			let query=this.$route.query;
			for(var i in query){
				this.form[i]=query[i]
			}

			//读取货源
			this.get_page_data()
		},
		methods: {
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},
			invoice_info(item){
				if(item.invoice_ishow){
					item.invoice_ishow = !item.invoice_ishow
					return
				}
				//加载过
				if(item.ser_state ==1){
					item.invoice_ishow = !item.invoice_ishow
					return
				}
				//是否加载
				item.ser_state=1
				Loading.service(this.options);
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_invoice_list_by_shipper',
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						invoice_num:item.invoice_num,
						for_export:1,
						...this.page,
					},
					callback:(res)=>{
						let loadingInstance = Loading.service(this.options);
							this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
							loadingInstance.close();
						});
						let data = res.msg

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						if(data.max==0){
							
							this.invoice_num_choosed={};

							//渲染
							this.list.data=[]

							return;
						}

						//tord_list预处理
						let tord_list={}
						for(var tord_item of data.tord_list){

							//成交时间
							tord_item.creat_time_text=this.$my.other.totime(tord_item.creat_time);
							
							//发车时间
							if(tord_item.start_time>0){//已发车
								tord_item.start_time_text=this.$my.other.totime(tord_item.start_time);
							}else tord_item.start_time_text="未发车";
							
							//到货时间
							if(tord_item.arrived_time>0){//已到货
								tord_item.arrived_time_text=this.$my.other.totime(tord_item.arrived_time);
							}else tord_item.arrived_time_text="未到货";

							//装货联系人
							if(tord_item.case_link_man){
								tord_item.case_link_man_text=tord_item.case_link_man;
							}else tord_item.case_link_man_text="无";

							//装货联系电话
							if(tord_item.case_link_tel){
								tord_item.case_link_tel_text=tord_item.case_link_tel;
							}else tord_item.case_link_tel_text="无";

							//卸货联系人
							if(tord_item.aim_link_man){
								tord_item.aim_link_man_text=tord_item.aim_link_man;
							}else tord_item.aim_link_man_text="无";

							//卸货联系电话
							if(tord_item.aim_link_tel){
								tord_item.aim_link_tel_text=tord_item.aim_link_tel;
							}else tord_item.aim_link_tel_text="无";

							/* 装货地其他数据(json) */
							tord_item.case_other_obj=JSON.parse(tord_item.case_other);

							//装货时间
							if(!tord_item.case_other_obj.case_time_start && tord_item.case_other_obj.case_time_end){//只有止点

								tord_item.case_other_obj.case_date=this.$my.other.todate(tord_item.case_other_obj.case_time_end)+"以前";

							}else if(tord_item.case_other_obj.case_time_start && !tord_item.case_other_obj.case_time_end){//只有起点

								tord_item.case_other_obj.case_date=this.$my.other.todate(tord_item.case_other_obj.case_time_start)+"以后";

							}else if(tord_item.case_other_obj.case_time_start && tord_item.case_other_obj.case_time_end){//都有

								tord_item.case_other_obj.case_date=this.$my.other.todate(tord_item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(tord_item.case_other_obj.case_time_end);

							}else if(!tord_item.case_other_obj.case_time_start && !tord_item.case_other_obj.case_time_end){//都没有

								tord_item.case_other_obj.case_date="未设置";
							}

							/* 卸货地其他数据(json) */
							tord_item.aim_other_obj=JSON.parse(tord_item.aim_other);

							//卸货时间
							if(!tord_item.aim_other_obj.aim_time_start && tord_item.aim_other_obj.aim_time_end){//只有止点

								tord_item.aim_other_obj.aim_date=this.$my.other.todate(tord_item.aim_other_obj.aim_time_end)+"以前";

							}else if(tord_item.aim_other_obj.aim_time_start && !tord_item.aim_other_obj.aim_time_end){//只有起点

								tord_item.aim_other_obj.aim_date=this.$my.other.todate(tord_item.aim_other_obj.aim_time_start)+"以后";

							}else if(tord_item.aim_other_obj.aim_time_start && tord_item.aim_other_obj.aim_time_end){//都有

								tord_item.aim_other_obj.aim_date=this.$my.other.todate(tord_item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(tord_item.aim_other_obj.aim_time_end);

							}else if(!tord_item.aim_other_obj.aim_time_start && !tord_item.aim_other_obj.aim_time_end){//都没有
								
								tord_item.aim_other_obj.aim_date="未设置";
							}

							//运单状态
							switch(tord_item.status){
								case '1':tord_item.status_text="待发车";break;
								case '2':tord_item.status_text="运输中";break;
								case '3':tord_item.status_text="已到货";break;
							}

							//结算方式
							switch(tord_item.settlement_type){
								case '1':tord_item.settlement_type_text="按吨数";break;
								case '2':tord_item.settlement_type_text="按方数";break;
								case '3':tord_item.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(tord_item.is_need_return){
								case '1':tord_item.is_need_return_text="需要回程";break;
								case '2':tord_item.is_need_return_text="不需要回程";break;
							}

							//匹配方式
							switch(tord_item.match_way){
								case '1':tord_item.match_way_text="收款人抢单";break;
								case '2':tord_item.match_way_text="指定后收款人接单";break;
								case '3':tord_item.match_way_text="收款人竞价中标";break;
								case '4':tord_item.match_way_text="收款人扫码接单";break;
								case '5':tord_item.match_way_text="网点线下匹配";break;
								case '6':tord_item.match_way_text="合作方匹配";break;
								case '7':tord_item.match_way_text="后台匹配";break;
							}

							//应收总额
							if(tord_item.cost_info.freight_total){
								tord_item.cost_info.freight_total_text=tord_item.cost_info.freight_total+" 元";
							}else tord_item.cost_info.freight_total_text="未设置";

							//应付总额
							if(tord_item.cost_info.give_carrier_total){
								tord_item.cost_info.give_carrier_total_text=tord_item.cost_info.give_carrier_total+" 元";
							}else tord_item.cost_info.give_carrier_total_text="未设置";

							//已付总额
							tord_item.cost_info.has_payed_total_text=tord_item.cost_info.has_payed_total+" 元"

							//运费支付状态
							switch(tord_item.payed_status){
								case '1':tord_item.payed_status_text="未支付";break;
								case '2':tord_item.payed_status_text="部分支付";break;
								case '3':tord_item.payed_status_text="支付完毕";break;
							}

							//运费结算状态
							switch(tord_item.settled_status){
								case '1':tord_item.settled_status_text="未结算";break;
								case '2':tord_item.settled_status_text="部分结算";break;
								case '3':tord_item.settled_status_text="结算完毕";break;
							}

							//缓存数据
							tord_list[tord_item.truck_tord_num]=tord_item;
						}

						//payed_list预处理
						let payed_list={};
						for(let payed_item of data.payed_list){

							//支付时间
							payed_item.creat_time_text=this.$my.other.totime(payed_item.creat_time);


							//开票时间
							payed_item.end_time_text=this.$my.other.totime(payed_item.end_time);
							
							//计算本次结给承运人的全部金额
							payed_item.this_settled=parseFloat(payed_item.this_settled_of_truck_owner)+parseFloat(payed_item.this_settled_of_driver)

							//服务费率模式: 显示服务费 显示结给司机的钱
							if(payed_item.calc_mode==1){

								//服务费
								payed_item.service_charge_text=(payed_item.this_payed-payed_item.this_settled).toFixed(2)+"元"

								//结给司机的钱
								payed_item.this_settled_text=payed_item.this_settled+"元"
							
							//固定金额模式
							}else if(payed_item.calc_mode==2){

								//服务费
								payed_item.service_charge_text="--"

								//结给司机的钱
								payed_item.this_settled_text="--"
							}
							
							//本次支付类型(1:现金,2:燃油费,3:过路费)
							switch(payed_item.this_pay_type){
								case '1':payed_item.this_pay_type_text="现金";break;
								case '2':payed_item.this_pay_type_text="燃油费";break;
								case '3':payed_item.this_pay_type_text="过路费";break;
							}

							//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
							switch(payed_item.pay_status){
								case '1':payed_item.pay_status_text="未支付";break;
								case '2':payed_item.pay_status_text="支付中";break;
								case '3':
									payed_item.pay_status_text=`支付成功 ${this.$my.other.totime(payed_item.payed_time)}`;
									break;
								case '4':
									payed_item.pay_status_text=`支付失败 ${this.$my.other.totime(payed_item.payed_time)}`;
									break;
							}

							//本次支付
							payed_item.this_payed_text=payed_item.this_payed+"元"

							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch(payed_item.invoice_status){
								case '1':payed_item.invoice_status_text="未申请";break;
								case '2':payed_item.invoice_status_text="申请中";break;
								case '3':
									payed_item.invoice_status_text=`开票成功 ${this.$my.other.totime(payed_item.invoiced_time)}`;
									break;
								case '4':
									payed_item.invoice_status_text=`开票失败 ${this.$my.other.totime(payed_item.invoiced_time)}`;
									break;
							}
							
							//本次发票金额
							payed_item.this_invoiced_text=payed_item.this_invoiced+"元"

							//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(payed_item.settle_status_of_truck_owner){
								case '1':payed_item.settle_status_of_truck_owner_text="未申请";break;
								case '2':payed_item.settle_status_of_truck_owner_text="待打款";break;
								case '3':
									payed_item.settle_status_of_truck_owner_text=`打款成功 ${this.$my.other.totime(payed_item.settled_time_of_truck_owner)}`;
									break;
								case '4':
									payed_item.settle_status_of_truck_owner_text=`打款失败 ${this.$my.other.totime(payed_item.settled_time_of_truck_owner)}`;
									break;
							}

							//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(payed_item.settle_status_of_driver){
								case '1':payed_item.settle_status_of_driver_text="未申请";break;
								case '2':payed_item.settle_status_of_driver_text="待打款";break;
								case '3':
									payed_item.settle_status_of_driver_text=`打款成功 ${this.$my.other.totime(payed_item.settled_time_of_driver)}`;
									break;
								case '4':
									payed_item.settle_status_of_driver_text=`打款失败 ${this.$my.other.totime(payed_item.settled_time_of_driver)}`;
									break;
							}

							//
							if(!payed_list[payed_item.invoice_num]){
								payed_list[payed_item.invoice_num]=[];
							}
							payed_list[payed_item.invoice_num].push(payed_item);
						}
												//invoice_list预处理
						let invoice_num_choosed={};
						for(var invoice_item of data.invoice_list){

							//申请开票时间
							invoice_item.creat_time_text=this.$my.other.totime(invoice_item.creat_time);

							//开票状态(1:申请中,2:已开出,3:已驳回)
							switch(invoice_item.status){
								case '1':invoice_item.invoice_status_text="待开票";break;
								case '2':
									invoice_item.invoice_status_text=`已开出 ${this.$my.other.totime(invoice_item.end_time)}`;
									break;
								case '3':
									invoice_item.invoice_status_text=`已驳回 ${this.$my.other.totime(invoice_item.end_time)}`;
									break;
							}

							//bill_num_text
							if(!(invoice_item.status==2&&invoice_item.bill_num)){
								invoice_item.bill_num="无"
							}
							invoice_item.invoice_ishow = false
							//创建运单列表节点
							invoice_item.tord_list={};

							//取出此开票申请对应的支付记录
							var payed_list_of_invoice_item=payed_list[invoice_item.invoice_num];
							if(!payed_list_of_invoice_item){
								payed_list_of_invoice_item=[];
							}
							for(var payed_item of payed_list_of_invoice_item){

								//查看某条支付记录所在的运单是否在运单列表节点里
								if(!invoice_item.tord_list[payed_item.truck_tord_num]){
									invoice_item.tord_list[payed_item.truck_tord_num]=JSON.parse(JSON.stringify(tord_list[payed_item.truck_tord_num]));
									invoice_item.tord_list[payed_item.truck_tord_num].payed_list=[];
								}

								//把支付记录置入运单列表
								invoice_item.tord_list[payed_item.truck_tord_num].payed_list.push(JSON.parse(JSON.stringify(payed_item)));
							}

							item.tord_list=Object.values(invoice_item.tord_list)
							item.invoice_ishow = !item.invoice_ishow
						}
					}
				})
			},
			//多选
			all_choose(event){//全选
				if(event){
					for(var invoice_num in this.invoice_num_choosed){
						this.invoice_num_choosed[invoice_num].checked=true;
					}
				}else{
					for(var invoice_num in this.invoice_num_choosed){
						this.invoice_num_choosed[invoice_num].checked=false;
					}
				}
			},
			get_choosed(){//获取选中的值
				let choosed=[];
				for(var invoice_num in this.invoice_num_choosed){
					if(this.invoice_num_choosed[invoice_num].checked){
						choosed.push(invoice_num);
					}
				}
				return choosed;
			},


			//导出
			choosed_out(){
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},


			//取消申请开票
			choosed_invoice_cancel(){//批量

				//获取选中值
				let choosed=this.get_choosed();

				//没有选择支付记录
				if(choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择'
					})
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定取消开票操作",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_invoice_cancel_by_shipper',
								invoice_nums:choosed,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},
			invoice_cancel(item){
				
				//询问
				this.$my.other.confirm({
					content:"点击确定取消开票申请",
					confirm:()=>{
						
						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_invoice_cancel_by_shipper',
								invoice_nums:[item.invoice_num]
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});
								
								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					invoice_num:'',//开票编号
					shipper_tel:'',//货主手机号
					status:'',//开票状态(1:待开票,2:已开出,3:已驳回)
					bill_num:'',//发票号
					creat_time_start:'',//申请开始时间
					creat_time_end:'',//申请结束时间
					invoiced_time_start:'',//开出开始时间
					invoiced_time_end:'',//开出结束时间
				}
				this.date_value=''
				this.invoice_time = ''
				this.page.p = 1
				this.get_page_data()
			},

			//搜索
			page_ser(){
				//时间处理
				if (this.date_value) {
					this.form.creat_time_start = parseInt(this.date_value[0] / 1000);
					this.form.creat_time_end= parseInt(this.date_value[1] / 1000);
				} else {
					this.form.creat_time_start = "";
					this.form.creat_time_end = "";
				}
				if (this.invoice_time) {
					this.form.invoiced_time_start = parseInt(this.invoice_time[0] / 1000);
					this.form.invoiced_time_end= parseInt(this.invoice_time[1] / 1000);
				} else {
					this.form.invoice_time_start = "";
					this.form.invoice_time_end = "";
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){
				Loading.service(this.options);
				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_invoice_list_by_shipper',
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						...this.form,
						...this.page
					},
					callback:(res)=>{
						let loadingInstance = Loading.service(this.options);
							this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
							loadingInstance.close();
						});
						let data = res.msg

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						if(data.max==0){
							
							this.invoice_num_choosed={};

							//渲染
							this.list.data=[]

							return;
						}

						//tord_list预处理
						// let tord_list={}
						// for(var tord_item of data.tord_list){

						// 	//成交时间
						// 	tord_item.creat_time_text=this.$my.other.totime(tord_item.creat_time);
							
						// 	//发车时间
						// 	if(tord_item.start_time>0){//已发车
						// 		tord_item.start_time_text=this.$my.other.totime(tord_item.start_time);
						// 	}else tord_item.start_time_text="未发车";
							
						// 	//到货时间
						// 	if(tord_item.arrived_time>0){//已到货
						// 		tord_item.arrived_time_text=this.$my.other.totime(tord_item.arrived_time);
						// 	}else tord_item.arrived_time_text="未到货";

						// 	//装货联系人
						// 	if(tord_item.case_link_man){
						// 		tord_item.case_link_man_text=tord_item.case_link_man;
						// 	}else tord_item.case_link_man_text="无";

						// 	//装货联系电话
						// 	if(tord_item.case_link_tel){
						// 		tord_item.case_link_tel_text=tord_item.case_link_tel;
						// 	}else tord_item.case_link_tel_text="无";

						// 	//卸货联系人
						// 	if(tord_item.aim_link_man){
						// 		tord_item.aim_link_man_text=tord_item.aim_link_man;
						// 	}else tord_item.aim_link_man_text="无";

						// 	//卸货联系电话
						// 	if(tord_item.aim_link_tel){
						// 		tord_item.aim_link_tel_text=tord_item.aim_link_tel;
						// 	}else tord_item.aim_link_tel_text="无";

						// 	/* 装货地其他数据(json) */
						// 	tord_item.case_other_obj=JSON.parse(tord_item.case_other);

						// 	//装货时间
						// 	if(!tord_item.case_other_obj.case_time_start && tord_item.case_other_obj.case_time_end){//只有止点

						// 		tord_item.case_other_obj.case_date=this.$my.other.todate(tord_item.case_other_obj.case_time_end)+"以前";

						// 	}else if(tord_item.case_other_obj.case_time_start && !tord_item.case_other_obj.case_time_end){//只有起点

						// 		tord_item.case_other_obj.case_date=this.$my.other.todate(tord_item.case_other_obj.case_time_start)+"以后";

						// 	}else if(tord_item.case_other_obj.case_time_start && tord_item.case_other_obj.case_time_end){//都有

						// 		tord_item.case_other_obj.case_date=this.$my.other.todate(tord_item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(tord_item.case_other_obj.case_time_end);

						// 	}else if(!tord_item.case_other_obj.case_time_start && !tord_item.case_other_obj.case_time_end){//都没有

						// 		tord_item.case_other_obj.case_date="未设置";
						// 	}

						// 	/* 卸货地其他数据(json) */
						// 	tord_item.aim_other_obj=JSON.parse(tord_item.aim_other);

						// 	//卸货时间
						// 	if(!tord_item.aim_other_obj.aim_time_start && tord_item.aim_other_obj.aim_time_end){//只有止点

						// 		tord_item.aim_other_obj.aim_date=this.$my.other.todate(tord_item.aim_other_obj.aim_time_end)+"以前";

						// 	}else if(tord_item.aim_other_obj.aim_time_start && !tord_item.aim_other_obj.aim_time_end){//只有起点

						// 		tord_item.aim_other_obj.aim_date=this.$my.other.todate(tord_item.aim_other_obj.aim_time_start)+"以后";

						// 	}else if(tord_item.aim_other_obj.aim_time_start && tord_item.aim_other_obj.aim_time_end){//都有

						// 		tord_item.aim_other_obj.aim_date=this.$my.other.todate(tord_item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(tord_item.aim_other_obj.aim_time_end);

						// 	}else if(!tord_item.aim_other_obj.aim_time_start && !tord_item.aim_other_obj.aim_time_end){//都没有
								
						// 		tord_item.aim_other_obj.aim_date="未设置";
						// 	}

						// 	//运单状态
						// 	switch(tord_item.status){
						// 		case '1':tord_item.status_text="待发车";break;
						// 		case '2':tord_item.status_text="运输中";break;
						// 		case '3':tord_item.status_text="已到货";break;
						// 	}

						// 	//结算方式
						// 	switch(tord_item.settlement_type){
						// 		case '1':tord_item.settlement_type_text="按吨数";break;
						// 		case '2':tord_item.settlement_type_text="按方数";break;
						// 		case '3':tord_item.settlement_type_text="按趟";break;
						// 	}
							
						// 	//是否回程
						// 	switch(tord_item.is_need_return){
						// 		case '1':tord_item.is_need_return_text="需要回程";break;
						// 		case '2':tord_item.is_need_return_text="不需要回程";break;
						// 	}

						// 	//匹配方式
						// 	switch(tord_item.match_way){
						// 		case '1':tord_item.match_way_text="收款人抢单";break;
						// 		case '2':tord_item.match_way_text="指定后收款人接单";break;
						// 		case '3':tord_item.match_way_text="收款人竞价中标";break;
						// 		case '4':tord_item.match_way_text="收款人扫码接单";break;
						// 		case '5':tord_item.match_way_text="网点线下匹配";break;
						// 		case '6':tord_item.match_way_text="合作方匹配";break;
						// 		case '7':tord_item.match_way_text="后台匹配";break;
						// 	}

						// 	//应收总额
						// 	if(tord_item.cost_info.freight_total){
						// 		tord_item.cost_info.freight_total_text=tord_item.cost_info.freight_total+" 元";
						// 	}else tord_item.cost_info.freight_total_text="未设置";

						// 	//应付总额
						// 	if(tord_item.cost_info.give_carrier_total){
						// 		tord_item.cost_info.give_carrier_total_text=tord_item.cost_info.give_carrier_total+" 元";
						// 	}else tord_item.cost_info.give_carrier_total_text="未设置";

						// 	//已付总额
						// 	tord_item.cost_info.has_payed_total_text=tord_item.cost_info.has_payed_total+" 元"

						// 	//运费支付状态
						// 	switch(tord_item.payed_status){
						// 		case '1':tord_item.payed_status_text="未支付";break;
						// 		case '2':tord_item.payed_status_text="部分支付";break;
						// 		case '3':tord_item.payed_status_text="支付完毕";break;
						// 	}

						// 	//运费结算状态
						// 	switch(tord_item.settled_status){
						// 		case '1':tord_item.settled_status_text="未结算";break;
						// 		case '2':tord_item.settled_status_text="部分结算";break;
						// 		case '3':tord_item.settled_status_text="结算完毕";break;
						// 	}

						// 	//缓存数据
						// 	tord_list[tord_item.truck_tord_num]=tord_item;
						// }

						//payed_list预处理
						// let payed_list={};
						// for(let payed_item of data.payed_list){

						// 	//支付时间
						// 	payed_item.creat_time_text=this.$my.other.totime(payed_item.creat_time);


						// 	//开票时间
						// 	payed_item.end_time_text=this.$my.other.totime(payed_item.end_time);
							
						// 	//计算本次结给承运人的全部金额
						// 	payed_item.this_settled=parseFloat(payed_item.this_settled_of_truck_owner)+parseFloat(payed_item.this_settled_of_driver)

						// 	//服务费率模式: 显示服务费 显示结给司机的钱
						// 	if(payed_item.calc_mode==1){

						// 		//服务费
						// 		payed_item.service_charge_text=(payed_item.this_payed-payed_item.this_settled).toFixed(2)+"元"

						// 		//结给司机的钱
						// 		payed_item.this_settled_text=payed_item.this_settled+"元"
							
						// 	//固定金额模式
						// 	}else if(payed_item.calc_mode==2){

						// 		//服务费
						// 		payed_item.service_charge_text="--"

						// 		//结给司机的钱
						// 		payed_item.this_settled_text="--"
						// 	}
							
						// 	//本次支付类型(1:现金,2:燃油费,3:过路费)
						// 	switch(payed_item.this_pay_type){
						// 		case '1':payed_item.this_pay_type_text="现金";break;
						// 		case '2':payed_item.this_pay_type_text="燃油费";break;
						// 		case '3':payed_item.this_pay_type_text="过路费";break;
						// 	}

						// 	//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
						// 	switch(payed_item.pay_status){
						// 		case '1':payed_item.pay_status_text="未支付";break;
						// 		case '2':payed_item.pay_status_text="支付中";break;
						// 		case '3':
						// 			payed_item.pay_status_text=`支付成功 ${this.$my.other.totime(payed_item.payed_time)}`;
						// 			break;
						// 		case '4':
						// 			payed_item.pay_status_text=`支付失败 ${this.$my.other.totime(payed_item.payed_time)}`;
						// 			break;
						// 	}

						// 	//本次支付
						// 	payed_item.this_payed_text=payed_item.this_payed+"元"

						// 	//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
						// 	switch(payed_item.invoice_status){
						// 		case '1':payed_item.invoice_status_text="未申请";break;
						// 		case '2':payed_item.invoice_status_text="申请中";break;
						// 		case '3':
						// 			payed_item.invoice_status_text=`开票成功 ${this.$my.other.totime(payed_item.invoiced_time)}`;
						// 			break;
						// 		case '4':
						// 			payed_item.invoice_status_text=`开票失败 ${this.$my.other.totime(payed_item.invoiced_time)}`;
						// 			break;
						// 	}
							
						// 	//本次发票金额
						// 	payed_item.this_invoiced_text=payed_item.this_invoiced+"元"

						// 	//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
						// 	switch(payed_item.settle_status_of_truck_owner){
						// 		case '1':payed_item.settle_status_of_truck_owner_text="未申请";break;
						// 		case '2':payed_item.settle_status_of_truck_owner_text="待打款";break;
						// 		case '3':
						// 			payed_item.settle_status_of_truck_owner_text=`打款成功 ${this.$my.other.totime(payed_item.settled_time_of_truck_owner)}`;
						// 			break;
						// 		case '4':
						// 			payed_item.settle_status_of_truck_owner_text=`打款失败 ${this.$my.other.totime(payed_item.settled_time_of_truck_owner)}`;
						// 			break;
						// 	}

						// 	//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
						// 	switch(payed_item.settle_status_of_driver){
						// 		case '1':payed_item.settle_status_of_driver_text="未申请";break;
						// 		case '2':payed_item.settle_status_of_driver_text="待打款";break;
						// 		case '3':
						// 			payed_item.settle_status_of_driver_text=`打款成功 ${this.$my.other.totime(payed_item.settled_time_of_driver)}`;
						// 			break;
						// 		case '4':
						// 			payed_item.settle_status_of_driver_text=`打款失败 ${this.$my.other.totime(payed_item.settled_time_of_driver)}`;
						// 			break;
						// 	}

						// 	//
						// 	if(!payed_list[payed_item.invoice_num]){
						// 		payed_list[payed_item.invoice_num]=[];
						// 	}
						// 	payed_list[payed_item.invoice_num].push(payed_item);
						// }

						//invoice_list预处理
						let invoice_num_choosed={};
						for(var invoice_item of data.invoice_list){

							//申请开票时间
							invoice_item.creat_time_text=this.$my.other.totime(invoice_item.creat_time);

							//开票状态(1:申请中,2:已开出,3:已驳回)
							switch(invoice_item.status){
								case '1':invoice_item.invoice_status_text="待开票";break;
								case '2':
									invoice_item.invoice_status_text=`已开出 ${this.$my.other.totime(invoice_item.end_time)}`;
									break;
								case '3':
									invoice_item.invoice_status_text=`已驳回 ${this.$my.other.totime(invoice_item.end_time)}`;
									break;
							}

							//bill_num_text
							if(!(invoice_item.status==2&&invoice_item.bill_num)){
								invoice_item.bill_num="无"
							}
							invoice_item.invoice_ishow = false
							//创建运单列表节点
							invoice_item.tord_list={};

							//取出此开票申请对应的支付记录
							// var payed_list_of_invoice_item=payed_list[invoice_item.invoice_num];
							// if(!payed_list_of_invoice_item){
							// 	payed_list_of_invoice_item=[];
							// }
							// for(var payed_item of payed_list_of_invoice_item){

							// 	//查看某条支付记录所在的运单是否在运单列表节点里
							// 	if(!invoice_item.tord_list[payed_item.truck_tord_num]){
							// 		invoice_item.tord_list[payed_item.truck_tord_num]=JSON.parse(JSON.stringify(tord_list[payed_item.truck_tord_num]));
							// 		invoice_item.tord_list[payed_item.truck_tord_num].payed_list=[];
							// 	}

							// 	//把支付记录置入运单列表
							// 	invoice_item.tord_list[payed_item.truck_tord_num].payed_list.push(JSON.parse(JSON.stringify(payed_item)));
							// }

							// invoice_item.tord_list=Object.values(invoice_item.tord_list)

							invoice_num_choosed[invoice_item.invoice_num]={
								checked:false,
								status:invoice_item.status,
							}
						}

						//
						this.invoice_num_choosed=invoice_num_choosed;

						//渲染
						this.list.data=data.invoice_list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		background-color: #fff;
		color: #666;
		text-align: left;
		height:calc(100% - 90px);
		font-size: 12px;
		border-bottom: 1px solid #0c1d40;
		.thead {
			background-color: #ccc;
		}

		.tbody {
			height: calc(100% - 41px);
			overflow-y: auto;
			.invoice_list {
				border: 1px solid #0c1d40;
				border-radius:4px;
				margin:10px;
				.invoice_info{
					display: flex;
					padding:4px;
					color:#efefef;
					background-color: #0c1d40;
					justify-content: flex-start;
					.item{
						margin: 3px;
					}
				}
			}
			.tord_list{
				border: 1px solid #409eff;
				border-radius:4px;
				margin:10px;
				.tord_info{
					display: flex;
					padding:4px;
					color:#efefef;
					background-color: #409eff;
					justify-content: space-between;
					.item{
						margin: 3px;
					}
				}
			}
			.payed_list{
				margin:5px;
				.payed_info{
					display: flex;
					justify-content: space-between;
					.item{
						margin: 3px;
						padding:3px;
					}
				}
			}
		}
	}
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>