//首页 个人中心 的页面路由
export default [
	{
		path:'sub_user/my_sub_user_list',
		component(){
			return import('@/pages/sub_user/my_sub_user_list.vue')
		}
	},{
		path:'sub_user/my_main_user_list',
		component(){
			return import('@/pages/sub_user/my_main_user_list.vue')
		}
	},{
		path:'sub_user/my_sub_role_list',
		component(){
			return import('@/pages/sub_user/my_sub_role_list.vue')
		}
	},
	{
		path:'sub_user/main_user_sys',
		component(){
			return import('@/pages/sub_user/pages/public/menu.vue')
		},
		children:[
			
			
			//重要:这里需要解决 两种子账号(全数据/部分数据) 不同显示问题 2021-4-30
			
			
			//实时单部分
			/* 主账户线路部分 */
			{
				path:'business_line/bl_creat',
				component(){
					return import('@/pages/sub_user/pages/real_time/business_line/bl_creat.vue')
				}
			},{
				path:'business_line/bl_edit',
				component(){
					return import('@/pages/sub_user/pages/real_time/business_line/bl_edit.vue')
				}
			},{
				path:'business_line/bl_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/business_line/bl_list.vue')
				}
			},
			//子账户个人中心
			{
				path:'sub_user/user_info',
				component(){
					return import('@/pages/sub_user/pages/user/user_info.vue')
				}
			},{
				path:'sub_user/all_ra_list',
				component(){
					return import('@/pages/sub_user/pages/user/all_ra_list.vue')
				}
			},{
				path:'sub_user/recharge_list',
				component(){
					return import('@/pages/sub_user/pages/user/recharge_list.vue')
				}
			},
			/* 主账户货源部分 */
			{
				path:'cargo/cargo_creat',
				component(){
					return import('@/pages/sub_user/pages/real_time/cargo/cargo_creat.vue')
				}
			},{
				path:'cargo/cargo_edit',
				component(){
					return import('@/pages/sub_user/pages/real_time/cargo/cargo_edit.vue')
				}
			},
			{
				path:'cargo/cargo_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/cargo/cargo_list.vue')
				}
			},
			//德邦界面路由
			{
				path:'cargo/db_cargo_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/db_cargo_list.vue')
				}
			},
			{
				path:'cargo/db_cargo_wait_quotation',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/db_cargo_wait_quotation.vue')
				}
			},
			{
				path:'cargo/db_act_tord',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/db_act_tord.vue')
				}
			},
			{
				path:'cargo/db_end_tord',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/db_end_tord.vue')
				}
			},
			{
				path:'cargo/db_wait_payment',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/db_wait_payment.vue')
				}
			},
			{
				path:'tord/payment_check_list1',
				component(){
					return import('@/pages/sub_user/pages/real_time/payment_check/payment_check_list1.vue')
				}
			},
			{
				path:'tord/payment_check_list2',
				component(){
					return import('@/pages/sub_user/pages/real_time/payment_check/payment_check_list2.vue')
				}
			},
			{
				path:'tord/payment_check_list3',
				component(){
					return import('@/pages/sub_user/pages/real_time/payment_check/payment_check_list3.vue')
				}
			},
			{
				path:'tord/request_payment_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/request_payment_list.vue')
				}
			},
			/* 注册信息部分 */
			{
				path:'truck_list',
				component(){
					return import('@/pages/register_info/truck_list.vue')
				}
			},{
				path:'driver_list',
				component(){
					return import('@/pages/register_info/driver_list.vue')
				}
			},
			/* 运单部分 */
			{
				path:'tord/tord_deal',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/deal.vue')
				}
			},
			{
				path:'tord/tord_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/tord_list.vue')
				}
			},{
				path:'tord/tord_act',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/act.vue')
				}
			},{
				path:'tord/tord_end',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/end.vue')
				}
			},
			/* 财务管理 */
			{
				path:'tord/tord_pay_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/finance/tord_pay_list.vue')
				}
			},
			/* 开票记录 */
			{
				path:'tord/tord_invoice',
				component(){
					return import('@/pages/sub_user/pages/real_time/finance_plan/tord_invoice.vue')
				}
			},
			/* 支付计划 */
			{
				path:'tord/tord_wait_list_plan',
				component(){
					return import('@/pages/sub_user/pages/real_time/finance_plan/tord_wait_list_plan.vue')
				}
			},
			//子账户新的支付计划
			{
				path:'tord/tord_pay_list_check',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/finance_plan1/tord_pay_list_check.vue')
				}
			},
			//德邦已报价
			{
				path:'cargo/db_quotation_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/db_cargo/db_quotation_list.vue')
				}
			},
			{
				path:'tord/tord_wait_pay_start',
				component(){
					return import('@/pages/sub_user/pages/real_time/finance_plan/tord_pay_start.vue')
				}
			},
			{
				path:'tord/tord_invoice_end',
				component(){
					return import('@/pages/sub_user/pages/real_time/finance_plan/tord_invoice_end.vue')
				}
			},
			//后录单部分
			/* 录入事件 */
			{
				path:'tord_after_time/event_add_by_excel',
				component(){
					return import('@/pages/sub_user/pages/after_time/event/event_add_by_excel.vue')
				}
			},{
				path:'tord_after_time/event_list',
				component(){
					return import('@/pages/sub_user/pages/after_time/event/event_list.vue')
				}
			},
			/* 开票相关 */
			{
				path:'tord_after_time/invoice_apply',
				component(){
					return import('@/pages/sub_user/pages/after_time/invoice/invoice_apply.vue')
				}
			},{
				path:'tord_after_time/invoice_done',
				component(){
					return import('@/pages/sub_user/pages/after_time/invoice/invoice_done.vue')
				}
			},{
				path:'tord_after_time/invoice_revoke',
				component(){
					return import('@/pages/sub_user/pages/after_time/invoice/invoice_revoke.vue')
				}
			},
			/* 运单相关 */
			{
				path:'tord_after_time/tord_before_check',
				component(){
					return import('@/pages/sub_user/pages/after_time/tord/tord_before_check.vue')
				}
			},{
				path:'tord_after_time/tord_check_pass',
				component(){
					return import('@/pages/sub_user/pages/after_time/tord/tord_check_pass.vue')
				}
			},{
				path:'tord_after_time/tord_check_unpass',
				component(){
					return import('@/pages/sub_user/pages/after_time/tord/tord_check_unpass.vue')
				}
			},
			//只读部分
			{
				path:'tord_real_time/bl_read_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/read/bl_read_list.vue')
				}
			},{
				path:'tord_real_time/tord_read_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/read/tord_read_list.vue')
				}
			},
			{
				path:'tord_real_time/cargo_read_list',
				component(){
					return import('@/pages/sub_user/pages/real_time/read/cargo_read_list.vue')
				}
			},
			{
				path:'tord_real_time/tord_batch_edit',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/tord_batch_edit.vue')
				}
			},{
				path:'tord_real_time/batch_tord_edit',
				component(){
					return import('@/pages/sub_user/pages/real_time/tord/batch_tord_edit.vue')
				}
			},
		]
	}
]