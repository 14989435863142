<template>
    <div style="background-color:#aaa;height: 100%">
		<el-container style="height: 100%">
			<div style="height:20%;background-color:#795a25">
				<!-- 左侧 -->
				<el-aside :width="main_aside_fold?'auto':'170px'">
					
					<!-- 深蓝色背景  #00161d  color #fff -->
					<el-menu 
						background-color="#fcf8ed"
						text-color="#795a25"
						active-text-color="#795a25"
						class="main_menu" 
						@select="select" 
						:collapse="main_aside_fold" 
						:unique-opened="true" 
						:collapse-transition="false" 
						:default-active="currentMenu"
						:style="{height:screenHeight+'px'}"
					>
						
						<!-- 左侧logo -->
						<div class="logo"><img src="../../res/img/logo.png"></div>

						<!-- 左侧菜单 -->
						<el-submenu v-for="(group,idx) in menu" :key="idx" :index="idx.toString()">
							
							<!-- 菜单组 -->
							<template slot="title">
								<i class="icon" :class="group.icon?group.icon:'el-icon-menu'"></i>
								&nbsp;&nbsp;
								<span slot="title">{{group.name}}</span>
							</template>
							
							
							
							<el-menu-item v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item>
							
		 					<!-- <el-menu-item v-if="group.name!='运单管理'" v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item> -->
							
							
							
							<!-- 单个菜单 通用-->
							<!-- <el-menu-item v-if="menu_tels.indexOf(user_info.tel)==-1" v-for="(item,index) in group.children" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item> -->
							
							<!-- 单个菜单-特殊情况 -->
							<!-- <el-menu-item v-if="group.name=='运单管理'" v-for="(item,index) in group.children_18621627027" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item> -->
							<!-- <el-menu-item v-if="group.name=='运单管理'&&menu_tels.indexOf(user_info.tel)-1" v-for="(item,index) in group.children_18621627027" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item> -->
							<!-- <el-menu-item v-if="group.name=='运单管理'&&yufu_tels.indexOf(user_info.tel)>-1" v-for="(item,index) in group.yufu_list" :key="index" :index="idx+'-'+index">{{item.name}}</el-menu-item> -->
							
							
						</el-submenu>
					</el-menu>
				</el-aside>
			</div>
		
		

			<!-- 右边 -->
			<el-container class="right_container">

				<!-- 头部一行 -->
				<el-header class="right_header" style="height:50px">
					<div class="left">
						<i :class="'text-xxl '+(main_aside_fold?'el-icon-s-unfold':'el-icon-s-fold')"  @click="menu_toggle"></i>
						<span class="open-page" @click="open_page">
							新窗口打开
						</span>
						
					</div>

					<div class="right">
						<!-- <el-popover
						    v-if="prompt_show"
							placement="top-start"
							title="提示"
							width="200"
							trigger="hover"
							:content="msg">
							<el-button slot="reference" icon="el-icon-warning-outline" class="logout_icon1"></el-button>
						</el-popover> -->
						<div v-if="prompt_show" class="el-icon-warning-outline logout_icon1" @mouseenter="move_start" @mouseleave="move_end"></div>
						<div class="text">{{company_info.name}} &nbsp;&nbsp;&nbsp; {{user_info.name}}</div>
						<div class="el-icon-switch-button logout_icon" @click="logout" ></div>
						
					</div>
				</el-header>
				<el-header class="right_header1" style="height:400px" v-if="Tips_show">
					<div @mouseenter="move_end">
						<span style="color:red">温馨提示:</span>{{msg}}
					</div>
				</el-header>
						
				<!-- 内容区域 -->
				<el-main class="container">
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
	
</template>
<script>
	import {mapMutations, mapState} from 'vuex'
	export default {
		data() {
			return {
				msg:'测试测试测试',
				prompt_show:null,
				Tips_show:false,
				//菜单是否折叠
				// fold: false,
				screenHeight:document.body.clientHeight,
				//选中菜单
				currentMenu: '',
				
				menu_tels:["18621627027"],

				yufu_tels:['18855370372',''],

				//菜单数据
				menu: [{
						name:'个人中心',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'用户信息',
								url:'user_info'
							},
							{
								name:'流水列表',
								url:'user_info/all_ra_list'
							},
							{
								name:'充值记录',
								url:'user_info/recharge_list'
							},
							{
								name:'项目列表',
								url:'user_info/project_list'
							}
							
						]
					},
					{
						name:'注册信息',
						icon:'el-icon-user-solid',
						roles:['admin'],
						children:[
							{
								name:'货车列表',
								url:'truck_list'
							},
							{
								name:'司机列表',
								url:'driver_list'
							},
						]
					},
					{
						name:'子账号系统',
						icon:'el-icon-potato-strips',
						roles:['admin'],
						children:[
							{
								name:'子账号列表',
								url:'sub_user/my_sub_user_list'
							},{
								name:'我是子账户',
								url:'sub_user/my_main_user_list'
							},
							{
								name:'角色列表',
								url:'sub_user/my_sub_role_list'
							}
						]
					},
					{
						name:'子账号系统',
						icon:'el-icon-potato-strips',
						roles:['son'],
						children:[{
								name:'我是子账户',
								url:'sub_user/my_main_user_list'
							}
						]
					},
					// {
					// 	name:'调度原',
					// 	icon:'el-icon-potato-strips',
					// 	roles:['son'],
					// 	children:[
					// 		{
					// 			name:'网点列表',
					// 			url:'outlets/outlets_list'
					// 		},{
					// 			name:'授权列表',
					// 			url:'outlets/auth_grant_list'
					// 		}
					// 	]
					// },
					// {
					// 	name:'子账号列表',
					// 	icon:'el-icon-potato-strips',
					// 	roles:['son'],
					// 	children:[
					// 		{
					// 			name:'调度员列表',
					// 			url:'son_account/dispatch_list'
					// 		}
					// 	]
					// },
					{
						name:'线路管理',
						icon:'el-icon-s-goods',
						roles:['admin'],
						children:[
							{
								name:'添加线路',
								url:'business_line/bl_creat'
							},{
								name:'线路列表',
								url:'business_line/bl_list'
							}
						]
					},{
						name:'货源管理',
						icon:'el-icon-s-goods',
						roles:['admin'],
						children:[
							{
								name:'发布货源',
								url:'tord_real_time/cargo_creat'
							},{
								name:'货源列表',
								url:'tord_real_time/cargo_list'
							},
							{
								name:'批量发货',
								url:'tord_real_time/excle_send_goods'
							}
						]
					},{
						name:'运单管理',
						icon:'el-icon-s-order',
						roles:['admin'],
						// children_18621627027:[//优通专用
						// 	{
						// 		name:'运单列表',
						// 		url:'tord_real_time/tord_act'
						// 	},

						// 	{
						// 		name:'待接单',
						// 		url:'tord_real_time/tord_deal'
						// 	},

						// 	{
						// 		name:'支付计划',
						// 		url:'tord_real_time/tord_wait_list_plan'
						// 	},

						// 	{
						// 		name:'已支付',
						// 		url:'tord_real_time/tord_pay_end'
						// 	},
						// 	{
						// 		name:'开票记录',
						// 		url:'tord_real_time/tord_invoice'
						// 	},
						// 	{
						// 		name:'支付记录',
						// 		url:'tord_real_time/tord_pay_list'
						// 	},

						// 	{
						// 		name:'批量修改运费',
						// 		url:'tord_real_time/tord_batch_edit'
						// 	},
						// 	{
						// 		name:'批量修改运单',
						// 		url:'tord_real_time/batch_tord_edit'
						// 	},
						// ],
						// yufu_list:[//垫资专用
						// 	{
						// 		name:'运单列表',
						// 		url:'tord_real_time/tord_act'
						// 	},

						// 	{
						// 		name:'待接单',
						// 		url:'tord_real_time/tord_deal'
						// 	},

						// 	{
						// 		name:'支付计划',
						// 		url:'tord_real_time/tord_wait_list_plan'
						// 	},
						// 	{
						// 		name:'已支付',
						// 		url:'tord_real_time/tord_pay_end'
						// 	},
						// 	{
						// 		name:'开票记录',
						// 		url:'tord_real_time/tord_invoice'
						// 	},
						// 	{
						// 		name:'支付记录',
						// 		url:'tord_real_time/tord_pay_list'
						// 	},

						// 	{
						// 		name:'批量修改运费',
						// 		url:'tord_real_time/tord_batch_edit'
						// 	},
						// 	{
						// 		name:'批量修改运单',
						// 		url:'tord_real_time/batch_tord_edit'
						// 	},
						// 	{
						// 		name:'垫资审核',
						// 		url:'tord_real_time/yufu_list'
						// 	},
						// ],
						children:[
							// {
							// 	name:'待接单',
							// 	url:'tord_real_time/tord_deal'
							// },
							// {
							// 	name:'运单列表',
							// 	url:'tord_real_time/tord_act'
							// },
							// {
							// 	name:'支付计划',
							// 	url:'tord_real_time/tord_wait_list'
							// },
							// {
							// 	name:'已结束',
							// 	url:'tord_real_time/tord_end'
							// },
							// {
							// 	name:'支付记录',
							// 	url:'tord_real_time/tord_pay_list'
							// },{
							// 	name:'开票申请',
							// 	url:'tord_real_time/tord_invoice_list'
							// },
						]
					},
					// {
					// 	name:'后录单',
					// 	icon:'el-icon-s-order',
					// 	children:[
					// 		{
					// 			name:'excel录入',
					// 			url:'tord_after_time/event_add_by_excel'
					// 		},{
					// 			name:'录入记录',
					// 			url:'tord_after_time/event_list'
					// 		},{
					// 			name:'待审核',
					// 			url:'tord_after_time/tord_before_check'
					// 		},{
					// 			name:'审核通过',
					// 			url:'tord_after_time/tord_check_pass'
					// 		},{
					// 			name:'审核驳回',
					// 			url:'tord_after_time/tord_check_unpass'
					// 		},{
					// 			name:'开票申请',
					// 			url:'tord_after_time/invoice_apply'
					// 		},{
					// 			name:'开票完成',
					// 			url:'tord_after_time/invoice_done'
					// 		},{
					// 			name:'开票失败',
					// 			url:'tord_after_time/invoice_revoke'
					// 		}
					// 	]
					// },
					// {
					// 	name:'工具集',
					// 	icon:'el-icon-s-tools',
					// 	children:[
					// 		{
					// 			name:'轨迹查询',
					// 			icon:'el-icon-menu',
					// 			url:'tools/truck_locus'
					// 		}
					// 	]
					// }
				],
			}
		},
		computed:{
			...mapState(['main_aside_fold','user_info','company_info'])
		},
		created() {
			console.log('当前版本1.1.0')
			this.init()
			this.openDefault();
			this.prompt_date()
		},
		// mounted(){
			
		// },
		methods: {

			...mapMutations(['set_main_aside_fold','end_of_logout','end_of_login']),
			//初始化
			init(){

				//读取菜单权限
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'is_show_my_child',
					},
					callback:(data)=>{
						let obj = data.pc.main_user
						let children = []
						for(let key in obj){
							if(key=='tord_act'&& obj[key]==1){
							
								children.push({
									name: "运单列表",
									url: "tord_real_time/tord_act"
								})
							}
						if(key=='tord_deal'&& obj[key]==1){
								children.push({
									name: "待接单",
									url: "tord_real_time/tord_deal"
								})
							}
						if(key=='tord_wait_list_plan'&& obj[key]==1){
								children.push({
									name: "支付计划",
									url: "tord_real_time/tord_wait_list_plan"
								})
							}
						if(key=='tord_pay_end'&& obj[key]==1){
								children.push({
									name: "已支付",
									url: "tord_real_time/tord_pay_end"
								})
							}
						if(key=='tord_invoice'&& obj[key]==1){
								children.push({
									name: "开票记录",
									url: "tord_real_time/tord_invoice"
								})
							}
						if(key=='tord_invoice_list'&& obj[key]==1){
							children.push({
								name: "开票申请记录",
								url: "tord_real_time/tord_invoice_list"
							})
						}
						if(key=='tord_pay_list'&& obj[key]==1){
								children.push({
									name: "支付记录",
									url: "tord_real_time/tord_pay_list"
								})
							}
						if(key=='tord_batch_edit'&& obj[key]==1){
								children.push({
									name: "批量修改运费",
									url: "tord_real_time/tord_batch_edit"
								})
							}
						if(key=='batch_tord_edit'&& obj[key]==1){
								children.push({
									name: "批量修改运单",
									url: "tord_real_time/batch_tord_edit"
								})
							}
						if(key=='db_list_bj'&& obj[key]==1){
						        children.push({
									name: "德邦待报价",
									url: "min_cargo/db_cargo_list"
								})
							}
						if(key=='db_list_zb'&& obj[key]==1){
						       children.push({
									name: "已中标未指派",
									url: "min_cargo/db_cargo_wait_quotation"
								})
							}
						if(key=='db_list_ys'&& obj[key]==1){
						        children.push({
									name: "已指派运输中",
									url: "min_cargo/db_act_tord"
								})
							}
						if(key=='db_list_qs'&& obj[key]==1){
						        children.push({
									name: "已签收待支付",
									url: "min_cargo/db_wait_payment"
								})
							}
						if(key=='db_list_wj'&& obj[key]==1){
								children.push({
									name: "已完结",
									url: "min_cargo/db_end_tord"
								})
							}
					}
						if(this.menu.length>3){
							this.menu[5].children = children
						}
						
					}
				});
			},
			move_start(){
				this.Tips_show = true
			},
			move_end(){
				this.Tips_show = false
			},
			tord_prompt_by_shipper(){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_prompt_by_shipper',
					},
					callback:(data)=>{
						if(!data){
							this.prompt_show = false
						}else{
							this.prompt_show = true
							this.msg = data
							this.Tips_show = true
						}
					}
				});
			},
			//打开新的浏览器页面
			open_page(){
				window.open('#'+this.$route.path)
			},
			prompt_date(){
				let date = localStorage.getItem('date')
				// var currentDate  = new Date()
				// currentDate.setDate(currentDate.getDate() - 1);
				// var a = currentDate.toLocaleDateString()
				let current_date =  new Date().toLocaleDateString()

				if(date){
					if(date==current_date){
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_prompt_by_shipper',
							},
							callback:(data)=>{
								if(!data){
									this.prompt_show = false
								}else{
									this.prompt_show = true
									this.msg = data
									
								}
							}
						});
						return
					}else{
						localStorage.setItem('date',current_date)
						this.tord_prompt_by_shipper()
					}
				}else{
					localStorage.setItem('date',current_date)
					this.tord_prompt_by_shipper()
				}

			},
			//菜单展开折叠
			menu_toggle() {
				let new_fold = this.main_aside_fold ? false : true
				this.set_main_aside_fold(new_fold)
			},
			select(index,indexPath) {
				
				var idx = indexPath[1].split("-")
				var menu = this.menu[idx[0]].children[idx[1]]
				var path = "/pages/sub_user/main_user_sys/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
			},
			//菜单跳转
			select(index,indexPath) {
				var idx = indexPath[1].split("-")

				let is_menu_tels=parseInt(this.menu_tels.indexOf(this.user_info.tel));
				var menu = this.menu[idx[0]].children[idx[1]]
				var path = "/pages/"+menu.url.replace("#", "")
				if (path != this.$route.fullPath) {
					this.$router.push({ path: path })
				}
				
			},

			//退出登录
			logout(){
				this.$my.other.confirm({
					content:'退出登录,请确认?',
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'app_user_logout',
							},
							callback:(data)=>{
								localStorage.removeItem('login_set')

								localStorage.removeItem('date')
								//删除缓存
								this.end_of_logout();

								//跳转回登录页
								this.$router.push('/login');
							}
						});
					}
				});
			},

			//展开并选中当前路由菜单
			openDefault() {
				var user_id = localStorage.getItem('user_id')
				this.menu = this.menu.filter(menu=>menu.roles.includes(user_id))
				let menu = this.menu
				for (var i in menu) {
					
					for (var j in menu[i].children_18621627027) {
						var path = '/pages/'+menu[i].children_18621627027[j].url
						if (path == this.$route.path) {
							this.currentMenu = `${i}-${j}`
						}
					}
				}
			},
		}
	}
</script>
<style lang="scss" scoped>
    .open-page{
		font-size: 14px;
		margin-left: 10px;
	}
	.logo {
		text-align: center;
		padding: 15px 0 0 0;
		img {
			width: 60px;
			padding: 10px 0px;
		}
	}

	.main_menu {
		height: 100%;
		overflow-x: hidden;
		border-top-right-radius: 36px;
		border-bottom-right-radius: 36px;
		border: 1px solid rgba(0, 0, 0, 0.205);
		// .menu{

		// 	.group{

		// 		.icon{

		// 		}

		// 		.text{
					
		// 		}
		// 	}
		// }
	}

	.right_container{
		padding: 0;
		.right_header1{
			padding: 10px;
			width: 300px;
			line-height: 40px;
			font-size: 14px;
			background-color: #fff;
			position: absolute;
			margin-top: 50px;
			left: 10;
			right: 20px;
			top:0;
			bottom: 10;
			z-index: 1001;
		}
		.right_header{
			padding: 0;
			height: 50px;
			line-height: 50px;
			display: flex;
			justify-content: space-between;
			background-color: #795a25;

			.left{
				cursor: pointer;
				color: #fff;
				width: 200px;
				text-align: center;
			}
			.right{
				color: #fff;
				display: flex;
				padding-right: 15px;
				// .text{

				// }
				.logout_icon{
					cursor: pointer;
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					font-weight:600;
				}
				.logout_icon1{
					cursor: pointer;
					width: 50px;
					font-size: 26px;
					line-height: 50px;
					font-weight:600;
					text-align: center;
					background-color: #795a25;
					color: red;
					border: none;
				}
			}
		}

		.container{
			background-color:#aaa;
			
		}
	}
	.el-menu-item.is-active{
		width: 50px;
		border-radius: 5px;
		min-width:100%;
		color: #fff !important;
		background-color: #795a25 !important;
	}
	.Tips{
		// background-color: #795a25 !important;
		opacity:0;
		border: none;
	}
</style>