import { render, staticRenderFns } from "./db_wait_payment.vue?vue&type=template&id=548de816&scoped=true&"
import script from "./db_wait_payment.vue?vue&type=script&lang=js&"
export * from "./db_wait_payment.vue?vue&type=script&lang=js&"
import style0 from "./db_wait_payment.vue?vue&type=style&index=0&id=548de816&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548de816",
  null
  
)

export default component.exports