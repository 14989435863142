 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="线路编号">
				<el-input class="el_input" v-model="form.bl_num" placeholder="线路编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路名称">
				<el-input class="el_input" v-model="form.bl_name" placeholder="线路名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="发货地区">
				<el-cascader class="el_input" v-model="form.case_city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="卸货地区">
				<el-cascader class="el_input" v-model="form.aim_city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨数" value="1"></el-option>
					<el-option label="按方数" value="2"></el-option>
					<el-option label="按趟" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="必填重量">
				<el-select class="el_input" v-model="form.is_need_weight" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="必填体积">
				<el-select class="el_input" v-model="form.is_need_volume" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="自动发车">
				<el-select class="el_input" v-model="form.is_auto_tord_start" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否双边">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="保险购买">
				<el-select class="el_input" v-model="form.is_insure" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="已购买" value="1"></el-option>
					<el-option label="未购买" value="2"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="项目名称">
				<el-input class="el_input" v-model="form.item_name" placeholder="项目名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary"  style="background-color:#795A25;border: none;" @click="page_ser">查询</el-button>
				<el-button type="primary"  style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success"  style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="to_add_view">添加</el-button>
				<el-button type="success"  style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="bl_whole_excle">全部导出</el-button>
				<el-button type="success"  style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="download_csv">下载模板</el-button>
				<el-button type="success"  style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="page_enter">线路录入</el-button>
				<el-button type="success"  style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="get_bl_qrcode(user_info,3)">全部线路二维码</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table
			:data="list.data" 
			:border="true" 
			:stripe="true"
			 size="small"
			height="100%" 
			v-loading="list.loading"
			>
				<el-table-column label="添加时间" width="190">
					<template slot-scope="scope">
						<div>编号:{{scope.row.bl_num}}</div>
						<div>创建:{{scope.row.creat_time_text}}</div>
						<div>名称:{{scope.row.bl_name?scope.row.bl_name:'未设置'}}</div>
						<div v-if="scope.row.item_name">项目名称:{{scope.row.item_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}</div>
						<div class="tab_line_item">{{scope.row.case_other_obj.addr_info}}</div>
						<div class="tab_line_item">联系人:{{scope.row.case_link_man_text}} / {{scope.row.case_link_tel_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}</div>
						<div class="tab_line_item">{{scope.row.aim_other_obj.addr_info}}</div>
						<div class="tab_line_item">联系人:{{scope.row.aim_link_man_text}} / {{scope.row.aim_link_tel_text}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="收货/销售方信息">
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="scope.row.consignee">收货方名称:{{scope.row.consignee}}</div>
						<div class="tab_line_item" v-if="scope.row.consigneeid">收货方信用代码:{{scope.row.consigneeid}}</div>
						<div class="tab_line_item" v-if="scope.row.seller_name">销售方名称:{{scope.row.seller_name}}</div>
						<div class="tab_line_item" v-if="scope.row.seller_code">销售方信用代码:{{scope.row.seller_code}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="子账户信息">
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="scope.row.sub_user_info.name">名称:{{scope.row.sub_user_info.name}}</div>
						<div class="tab_line_item" v-if="scope.row.sub_user_info.tel">手机号:{{scope.row.sub_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="单价">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.price}}元 / {{scope.row.price_unit}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货物名称/运输距离"  width="130">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_name}}/{{scope.row.cargo_type}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费信息"  width="140">
					<template slot-scope="scope">
						<div class="tab_line_item">结算:{{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">运费:{{scope.row.freight_total_text}}</div>
						<div class="tab_line_item">保障服务费:{{scope.row.is_insure_text}}</div>
						<div class="tab_line_item" v-if="scope.row.yufu_total>0">预付金额:{{scope.row.yufu_total}}元</div>
					</template>
				</el-table-column>
				<el-table-column label="必填项/回程"  width="165">
					<template slot-scope="scope">
						<div style="display:flex;justify-content: space-between;">
							<div>
								<div class="tab_line_item">必填重量:{{scope.row.is_need_weight_text}}</div>
								<div class="tab_line_item">必填体积:{{scope.row.is_need_volume_text}}</div>
							</div>
							<div>
								<div class="tab_line_item">自动发车:{{scope.row.is_auto_tord_start_text}}</div>
								<div class="tab_line_item">是否双边:{{scope.row.is_need_return_text}}</div>
							</div>
						</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="110">
					<template slot-scope="scope">
						<el-button style="margin-left:10px" @click="to_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="bl_del(scope.row)" size="mini" type="text" style="margin-left:10px">删除</el-button>
						<el-button @click="open_driver_list(scope.row)" size="mini" type="text">司机列表</el-button>
						<el-button @click="get_bl_qrcode(scope.row,1)" size="mini" type="text">小程序二维码</el-button>
						<!-- <el-button @click="get_bl_qrcode2(scope.row,1)" size="mini" type="text">接单码</el-button> -->
						<el-button @click="get_bl_qrcode(scope.row,2)" type="text" size="mini">app二维码</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 二维码界面 -->
		<qrcodeImg 
			:src="qrcode.src" 
			:is_show="qrcode.is_show"
			:bl_data="qrcode.bl_data"
		></qrcodeImg>

		<!-- 绑定的司机列表 -->
		<driverList 
			:is_show="driver_list.is_show"
			:bl_info="driver_list.bl_info"
		></driverList>


     	 <!-- 批量录入线路列表 -->
		<blenter 
			:is_show="excle_enter_list.is_show"
			:bl_list="excle_enter_list.data"
			@closed="get_page_data"
		></blenter>
	</div>
</template>

<script>
	import {regionDataPlus} from 'element-china-area-data'
	import qrcodeImg from '@/components/qrcode.vue'
	import driverList from './bl_driver_list.vue'
	import { Loading } from 'element-ui';
	import blenter from './enter_list.vue'
	import {mapState} from 'vuex'
	export default {
		components: {
			qrcodeImg,
			driverList,
			blenter

		},
		data() {
			return {

				//省市县基础数据
				cities:regionDataPlus,

				//搜索条件
				form: {
					bl_num:'',//线路编号
					bl_name:'',//线路名称
					user_tel:'',//客户联系电话
					user_name:'',//客户联系人
					company_name:'',//客户公司名称
					cargo_name:'',//货物名称
					case_city:null,//发货省市区
					aim_city:null,//卸货省市区
					is_need_weight:'',//是否必填货物重量(1:是,2:否)
					is_need_volume:'',//是否必填货物体积(1:是,2:否)
					is_auto_tord_start:'',//接单自动发车(1:自动发车,2:不自动发车)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					is_insure:'',//保险是否购买(1购买,2未购买)
					item_name:'',//项目名称
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//货源二维码
				qrcode:{
					is_show:0,
					src:'',
					bl_data:{}
				},

				//绑定的司机列表
				driver_list:{
					is_show:0,
					bl_info:{},
				},
				excle_enter_list:{
					is_show:0,
					data:[]
				}
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		computed:{
			...mapState(['user_info'])
		},
		methods: {
			//录入数据
			page_enter(){
				this.$my.excle.importExcel((data, dataRef) => {
					if(data.length>1000){
						this.$my.other.msg({
							type:'info',
							str:'最大录入量为1000条,请修改后重新录入'
					    })
						return
					}
					var tord_list = []
					var bl_name_index = data[0].indexOf('线路名称')
					//获取对应位置下标
					var case_addr_index = data[0].indexOf('发货详细地址(包括省、市、区)')
					var case_link_man_index = data[0].indexOf('发货地联系人')
					var case_link_tel_index = data[0].indexOf('发货地联系电话')
					var aim_addr_index = data[0].indexOf('到货详细地址(包括省、市、区)')
					var aim_link_man_index = data[0].indexOf('到货地联系人')
					var aim_link_tel_index = data[0].indexOf('到货地联系电话')
					var cargo_name_index = data[0].indexOf('货物名称')
					var cargo_type_index = data[0].indexOf('货物类型')
					var settlement_type_index = data[0].indexOf('结算方式')
					var is_need_weight_index = data[0].indexOf('是否必填货物重量')
					var is_need_volume_index = data[0].indexOf('是否必填货物体积')
					var is_auto_tord_start_index = data[0].indexOf('是否自动发车')
					var freight_total_index = data[0].indexOf('总运费')
					var price_index = data[0].indexOf('路线单价')
					var price_unit_index = data[0].indexOf('价格单位')
					var consignee_index = data[0].indexOf('收货方名称(公司或个人)')
					var consigneeid_index = data[0].indexOf('收货方统一社会信用代码或个人证件号码')

					var seller_name_index = data[0].indexOf('销售方名称(公司名称)')
					var seller_code_index = data[0].indexOf('销售方统一社会信用代码')
					
					//删除表头
					delete data[0]
					var dete = data[1]
					data.forEach((item,index)=> {
						switch(item[settlement_type_index]){
							case '按吨结算':item.settlement_type="1";break;
							case '按方结算':item.settlement_type="2";break;
							case '按趟结算':item.settlement_type="3";break;
						}
						switch(item[is_need_weight_index]){
							case '必填吨数':item.is_need_weight="1";break;
							case '不必填吨数':item.is_need_weight="2";break;
						}
						switch(item[is_need_volume_index]){
							case '必填体积':item.is_need_volume="1";break;
							case '不必填体积':item.is_need_volume="2";break;
						}
						switch(item[is_auto_tord_start_index]){
							case '自动发车':item.is_auto_tord_start="1";break;
							case '不自动发车':item.is_auto_tord_start="2";break;
						}
						tord_list.push({
							case_addr:item[case_addr_index],
							case_link_man:item[case_link_man_index],
							case_link_tel:Number(item[case_link_tel_index]),
							aim_addr:item[aim_addr_index],
							aim_link_man:item[aim_link_man_index],
							aim_link_tel:Number(item[aim_link_tel_index]),
							cargo_name:item[cargo_name_index],
							cargo_type:item[cargo_type_index],
							consignee:item[consignee_index],
							consigneeid:item[consigneeid_index],
							bl_name:item[bl_name_index],
							seller_code:item[seller_code_index],
							seller_name:item[seller_name_index],
							settlement_type_text:item[settlement_type_index],
							settlement_type:item.settlement_type,

							is_need_weight:item.is_need_weight,
							is_need_weight_text:item[is_need_weight_index],

							is_need_volume:item.is_need_volume,
							is_need_volume_text:item[is_need_volume_index],


							is_auto_tord_start:item.is_auto_tord_start,
							is_auto_tord_start_text:item[is_auto_tord_start_index],


							freight_total:Number(item[freight_total_index]).toFixed(2),
							price:Number(item[price_index]).toFixed(2),
							price_unit:item[price_unit_index],
						})
					});
					this.excle_enter_list.is_show ++
					this.excle_enter_list.data = tord_list
                });
			},
			download_csv(){
				//生成下载链接
				let link = document.createElement('a');
				link.href = 'https://zwbapi.jungong56.com/app/res/files/template/bl.xlsx';

				//对下载的文件命名
				link.download = "act.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				
				// this.truck_tord_nums=[];
			},
			//生成csv
			make_csv(data){
				var shunxu=[
					{key:"id",cname:"系统id"},
					{key:"bl_num",cname:"线路编号"},
					{key:"bl_name",cname:"线路名称"},
					{key:"creat_time_text",cname:"创建时间"},
					{key:"cargo_name",cname:"货物名称"},
					{key:"cargo_type",cname:"货物类型"},
					{key:"case_prov",cname:"发货地省份"},
					{key:"case_city",cname:"发货地城市"},
					{key:"case_county",cname:"发货地县区"},
					{key:"case_addr_info",cname:"发货地详细地址"},
					{key:"case_link_man_text",cname:"发货地联系人"},
					{key:"case_link_tel_text",cname:"发货地联系电话"},
					{key:"aim_prov",cname:"到货地省份"},
					{key:"aim_city",cname:"到货地城市"},
					{key:"aim_county",cname:"到货地县区"},
					{key:"aim_addr_info",cname:"到货地详细地址"},
					{key:"aim_link_man_text",cname:"到货地联系人"},
					{key:"aim_link_tel_text",cname:"到货地联系电话"},
					{key:"distance",cname:"运输距离"},
                    {key:"settlement_type_text",cname:"结算方式"},
					{key:"is_need_weight_text",cname:"必填重量"},
					{key:"is_need_volume_text",cname:"必填体积"},
					{key:"is_auto_tord_start_text",cname:"自动发车"},
				    {key:"is_need_return_text",cname:"是否双边"},
					{key:"creator_info",cname:"制单人信息"},
					
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "线路列表.xlsx";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			//全部导出
			bl_whole_excle(){
				Loading.service(this.options);
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_shipper',
						...this.form,
						...this.page,
						num:1000,
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){
							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							if(item.sub_user_info.name){
								item.creator_info = item.sub_user_info.name +"+"+ item.sub_user_info.tel
							}else{
								item.creator_info = item.shipper_info.name +"+"+ item.shipper_info.tel
							}
							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";
							//装货地其他数据(json)
							item.case_other_obj=item.case_other.replace('\n','')
							item.case_other_obj=JSON.parse(item.case_other);
							// item.case_addr_info=item.case_other_obj.addr_info

							//卸货地其他数据(json)
							item.aim_addr_info=item.aim_other.replace('\n','')
							item.aim_other_obj=JSON.parse(item.aim_other);
							//卸货联系人
							if(item.aim_link_man){
								item.aim_link_man_text=item.aim_link_man;
							}else item.aim_link_man_text="无";

							//卸货联系电话
							if(item.aim_link_tel){
								item.aim_link_tel_text=item.aim_link_tel;
							}else item.aim_link_tel_text="无";

							// //卸货地其他数据
							// item.aim_other = item.aim_other.replace(/\\/g, '');
							// item.aim_other_obj=JSON.parse(item.aim_other);

							//结算方式(1:按吨数,2:按方数,3:按趟)
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//运费
							if(item.freight_total>0){
								item.freight_total_text=item.freight_total+"元";
							}else item.freight_total_text="未设置"

							//是否必填货物重量(1:是,2:否)
							switch(item.is_need_weight){
								case '1':item.is_need_weight_text="是";break;
								case '2':item.is_need_weight_text="否";break;
							}

							//是否必填货物体积(1:是,2:否)
							switch(item.is_need_volume){
								case '1':item.is_need_volume_text="是";break;
								case '2':item.is_need_volume_text="否";break;
							}

							//接单自动发车(1:自动发车,2:不自动发车)
							switch(item.is_auto_tord_start){
								case '1':item.is_auto_tord_start_text="是";break;
								case '2':item.is_auto_tord_start_text="否";break;
							}

							//是否回程(1:需要,2:不需要)
							switch(item.is_need_return){
								case '1':item.is_need_return_text="是";break;
								case '2':item.is_need_return_text="否";break;
							}
							item.case_addr_info = item.case_other_obj.addr_info
							item.aim_addr_info = item.aim_other_obj.addr_info
						}
						
						this.make_csv(data.list)
					}
				})
			},
			//打开指定的司机列表
			open_driver_list(item){
				this.driver_list.bl_info={...item}
				this.driver_list.is_show++
			},

			//生成线路二维码
			get_bl_qrcode(item,code_type){
				if(code_type==3){
					//调接口
					this.$my.net.req({
						data:{
							mod:'truck_tord_real_time',
							ctr:'get_bl_of_shipper_qrcode',
							shipper_tel:item.tel,
							code_type:2,
						},
						callback:(data)=>{

							//置入src
							this.qrcode.src=data.img

							// this.qrcode.bl_data={...item}

							//打开
							this.qrcode.is_show++
						}
					});
					return
				}
				//调接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_bl_qrcode',
						bl_num:item.bl_num,
						code_type
					},
					callback:(data)=>{

						//置入src
						this.qrcode.src=data.img
						this.qrcode.bl_data={...item}
						//打开
						this.qrcode.is_show++
					}
				});
			},
			
			//临时接单码
			get_bl_qrcode2(item,code_type){
			
				//调接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_bl_qrcode_web',
						bl_num:item.bl_num,
						code_type
					},
					callback:(data)=>{
			
						//置入src
						this.qrcode.src=data.img
						this.qrcode.bl_data={...item}
						//打开
						this.qrcode.is_show++
					}
				});
			},

			//删除
			bl_del(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除路线",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_real_time_bl_del_shipper',
								bl_num:item.bl_num
							},
							callback:(data)=>{
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//前往添加界面
			to_add_view(){

				//跳转
				this.$router.push({
					path:'/pages/business_line/bl_creat'
				});
			},

			//前往修改界面
			to_edit_view(item){

				//跳转
				this.$router.push({
					path:'/pages/business_line/bl_edit?id='+item.id
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					user_tel:'',//客户联系电话
					user_name:'',//客户联系人
					company_name:'',//客户公司名称
					cargo_name:'',//货物名称
					case_city:null,//发货省市区
					aim_city:null,//卸货省市区
					is_need_weight:'',//是否必填货物重量(1:是,2:否)
					is_need_volume:'',//是否必填货物体积(1:是,2:否)
					is_auto_tord_start:'',//接单自动发车(1:自动发车,2:不自动发车)
					is_insure:'',//保险是否购买(1购买,2未购买)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					item_name:'',//项目名称
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";

							//装货地其他数据(json)
							item.case_other_obj=item.case_other.replace('\n','')
							item.case_other_obj=JSON.parse(item.case_other);
							// item.case_addr_info=item.case_other_obj.addr_info

							//卸货地其他数据(json)
							item.aim_addr_info=item.aim_other.replace('\n','')
							item.aim_other_obj=JSON.parse(item.aim_other);
							//装货地其他数据
							// item.case_other_obj=JSON.parse(item.case_other);

							//卸货联系人
							if(item.aim_link_man){
								item.aim_link_man_text=item.aim_link_man;
							}else item.aim_link_man_text="无";

							//卸货联系电话
							if(item.aim_link_tel){
								item.aim_link_tel_text=item.aim_link_tel;
							}else item.aim_link_tel_text="无";

							//卸货地其他数据
							// item.aim_other = item.aim_other.replace(/\\/g, '');
							// item.aim_other_obj=JSON.parse(item.aim_other);

							//结算方式(1:按吨数,2:按方数,3:按趟)
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//运费
							if(item.freight_total>0){
								item.freight_total_text=item.freight_total+"元";
							}else item.freight_total_text="未设置"

							//是否必填货物重量(1:是,2:否)
							switch(item.is_need_weight){
								case '1':item.is_need_weight_text="是";break;
								case '2':item.is_need_weight_text="否";break;
							}

							//是否必填货物体积(1:是,2:否)
							switch(item.is_need_volume){
								case '1':item.is_need_volume_text="是";break;
								case '2':item.is_need_volume_text="否";break;
							}

							//接单自动发车(1:自动发车,2:不自动发车)
							switch(item.is_auto_tord_start){
								case '1':item.is_auto_tord_start_text="是";break;
								case '2':item.is_auto_tord_start_text="否";break;
							}
							//保险费
							switch(item.is_insure){
								case '1':item.is_insure_text="已购买";break;
								case '2':item.is_insure_text="未购买";break;
							}
							//是否回程(1:需要,2:不需要)
							switch(item.is_need_return){
								case '1':item.is_need_return_text="是";break;
								case '2':item.is_need_return_text="否";break;
							}
						}
						
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	.tab_height{
		height:calc(100% - 140px);
		
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>